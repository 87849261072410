import React, { FormEvent, useEffect, useState } from "react";
import {
  Container,
  ContentWrapper,
  GoBackButtonWrapper,
  Text,
  Title,
  WrapperContent,
  WrapperLeft,
  WrapperRight,
  WrapperButton,
  WrapperForm,
  Button,
  ResendButton,
} from "./styles";



import { ReactComponent as ArrowLeft } from "../../assets/LParrowLeft.svg";

import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";

import { api, parseError } from "../../services/api";
import { ExclamationIcon } from "@heroicons/react/solid";
import { addHours, format } from "date-fns";
import { useClient } from "../../hooks/client";
import axios from "axios";
import { useNotification } from "../../hooks/notification";
import { isValid } from 'date-fns'
import { useAuth } from "../../hooks/auth";
import { professions } from "../../utils/professions";
import { service, serviceError } from "../../services/ajio";
import { validateCellphone, validateCNPJ, validateCPF, validateDate, validateEmail } from "../../utils/validations";
import { FormInput, FormRadio, FormSelect } from "../../components/LPFormButton2";


type IFormInfo = {
  "type": "company";
  "name": string;
  "document": string;
  "phone": string;
  "email": string;
  "password": string;
  "password2": string;
  "pin": string;
  "legalName": string;
  "legalNature": string;
  "establishmentDate": string;
  "establishmentType": string;
  "mainCnae": string;
  "revenue": string;
  "address": {
    "street": string;
    "number": string;
    "extra": string;
    "neighborhood": string;
    "city": string;
    "state": string; //only the UF code
    "postalCode": string; //only numbers
  },
  "partner": {
    "isPep": boolean;
    "name": string;
    "document": string;
    "phone": string;
    "email": string;
    "birthDate": string;
    "motherName": string;
    "address": {
      "street": string;
      "number": string;
      "extra": string;
      "neighborhood": string;
      "city": string;
      "state": string; //only the UF code
      "postalCode": string; //only numbers
    }
  }
}

type IFormError = {
  "type"?: string;
  "name"?: string;
  "document"?: string;
  "phone"?: string;
  "email"?: string;
  "password"?: string;
  "password2"?: string;
  "pin"?: string;
  "legalName"?: string;
  "legalNature"?: string;
  "establishmentDate"?: string;
  "establishmentType"?: string;
  "mainCnae"?: string;
  "revenue"?: string;
  "address"?: {
    "street"?: string;
    "number"?: string;
    "extra"?: string;
    "neighborhood"?: string;
    "city"?: string;
    "state"?: string; //only the UF code
    "postalCode"?: string; //only numbers
  },
  "partner"?: {
    "isPep"?: string;
    "name"?: string;
    "document"?: string;
    "phone"?: string;
    "email"?: string;
    "birthDate"?: string;
    "motherName"?: string;
    "address"?: {
      "street"?: string;
      "number"?: string;
      "extra"?: string;
      "neighborhood"?: string;
      "city"?: string;
      "state"?: string; //only the UF code
      "postalCode"?: string; //only numbers
    }
  }
}

interface CompanieProps {
  status: string | null;
}

const getLegalNature = (establishmentType:string) => {
  if(establishmentType === "MEI") return "2135";
  if(establishmentType === "LTDA") return "2062";
  if(establishmentType === "SA") return "2046";
  if(establishmentType === "EIRELI") return "2305";
  if(establishmentType === "COOP") return "2011";

  return "2028"
}



export default function Companies ({ status }: CompanieProps) {
  const { signOut, signIn, user } = useAuth()
  const location = useLocation();
  const { client } = useClient();
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const { setOpenNotification } = useNotification();


  const [formInfo, setFormInfo] = useState<IFormInfo>({} as IFormInfo);
  const [error, setError] = useState<IFormError>({} as IFormError); // field specific errors
  const [errorMessage, setErrorMessage] = useState("") // general form erros
  const [typeDoc, setTypeDoc] = useState('SELFIE');

  const [step, setStep] = useState(() => {
    const statusStorage = status || localStorage.getItem('@stricv2:status');

    if (statusStorage) {
      if (statusStorage === 'WAITING_TOKEN') {
        return 2;
      }

      if (statusStorage === 'WAITING_FLOW') {
        return 3;
      }

      if (statusStorage === 'WAITING_PARTNERS') {
        return 3;
      }

      if (statusStorage === 'WAITING_ADDRESS') {
        return 5;
      }

      if (statusStorage === 'waiting_documents') {
        return 7;
      }

      if (statusStorage === 'under_review') {
        return 99;
      }

    }

    return 1;
  });

  const [sucess, setSuccess] = useState(() => {
    const statusStorage = localStorage.getItem('@stricv2:status');

    if (statusStorage === 'under_review' || statusStorage === 'under_review') {
      return true;
    }

    return false;
  });

  const [token, setToken] = useState(() => {
    const tokenStorage = localStorage.getItem('@stricv2:id');
    if (tokenStorage) {
      return tokenStorage;
    }
    return '';
  });


  const navigation = useNavigate();
  // const { setOpenNotification } = useNotification();
  const handleGoBack = async () => {
    await signOut();
    navigation("/signin");
  };

  useEffect(() => {
    if (!location.search || location.search === null || location.search === '') {
      navigation('/signin');
    }
  }, [location.search, navigation]);



  const handleOneSubmit = async () => {

    setError({} as IFormError);

    // CNPJ xxx.xxx.xxx-xx
    if(formInfo.document.length < 18){
      setError({document: 'CNPJ Inválido.'});
      return;
    }

    if(!validateCNPJ(formInfo.document.replace(/[/,.,-\s]/g, ""))){
      setError({document: 'CNPJ Inválido.'});
      return;
    }

    // Email
    if(formInfo.email.length < 6){
      setError({email: 'Preencha o email.'});
      return;
    }

    if(!validateEmail(formInfo.email)){
      setError({email: 'Email Inválido.'});
      return;
    }

    // Phone (xx) xxxxx-xxxx
    if(formInfo.phone.length < 14){
      setError({phone: 'Telefone Inválido.'});
      return;
    }


    setLoading(true);

    try {

      setLoading(false);
      setStep(2);

    } catch (err) {
      console.log(err);
      const error = serviceError(err);
      const errorAll = err as any;
      setLoading(false);
      if (error.code === 'document.already' || error.code === 'email.already') {
        setOpenNotification({title:'Alerta', notification:'Nós já nos conhecemos!\n\nIdentificamos que você já possui um vínculo com a gente.\n\nPara continuar, utilize sua senha e comece a desfrutar de todas as vantangens.', type: "warning"});
      } else {
        setOpenNotification({title: "Erro", notification: error.friend || errorAll?.response?.data?.message?.[0], type: "error"});
      }
    }
  }

  const handleTwoSubmitStepPJ = async () => {

    setError({} as IFormError);

    if(formInfo.establishmentDate.length < 10){
      setError({establishmentDate: 'Preencha a data de fundação completa.'});
      return;
    }

    if(!validateDate(formInfo.establishmentDate)){
      setError({establishmentDate: 'Data inválida.'});
      return;
    }

    if(formInfo.mainCnae.length < 7){
      setError({mainCnae: 'Preencha o CNAE completo.'});
      return;
    }

    if(formInfo.revenue === "R$ 0,00" || formInfo.revenue === undefined){
      setError({revenue: 'Preencha sua receita.'});
      return;
    }
    // Only when establishmentType is one of these, the legal nature field shows up
    if(formInfo.establishmentType === "DEMAIS" || formInfo.establishmentType === "SA"){
      if(!formInfo.legalNature){
        setError({legalNature: "Selecione sua natureza jurídica."})
        return;
      }
    }


    setLoading(true);
    try {

      setLoading(false);
      setStep(3);
    } catch (err) {
      console.log(err);
      const error = serviceError(err);
      setLoading(false);
      setErrorMessage(error.friend);
    }
  }


  async function handleCep() {

    setError({} as IFormError);

    // xx.xxx-xxx
    if(formInfo.address.postalCode.length < 9){
      setError({...error, address: {...formInfo.address, postalCode: "Preencha o CEP completo."}});
      return;
    }

    const rawPostalCode = formInfo.address.postalCode.replace(/-/g, "")
    if (rawPostalCode.length >= 8) {
      setLoading(true);

      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${rawPostalCode}/json/`);
        console.log(data);
        if (data) {
          setFormInfo({...formInfo, address:{...formInfo.address, street: data.logradouro, neighborhood: data.bairro, city: data.localidade, state: data.uf}})
        }
        setLoading(false);
        setStep(4);
      } catch (err) {
        setLoading(false);
        setStep(4);
      }
    }
  }


  async function handleAddress() {

    setError({} as IFormError);

    if(formInfo.address.postalCode.length < 9){
      setError({...error, address: {...formInfo.address, postalCode: "Preencha o CEP completo."}});
      return;
    }

    if(formInfo.address.state.length !== 2){
      setError({...error, address: {...formInfo.address, state: "Estado Inválido."}});
      return;
    }

    if(formInfo.address.state.length !== 2){
      setError({...error, address: {...formInfo.address, state: "Estado Inválido."}});
      return;
    }

    setLoading(true);
    try {

      setLoading(false);
      setStep(5);
    } catch (err) {
      const myError = serviceError(err);
      setLoading(false);
      setErrorMessage(myError.friend);
    }
  }

  async function handlePartner() {

    setError({} as IFormError);
    setErrorMessage("");

        // Name and Mother Name Validations
        if(!formInfo.partner?.name?.includes(" ") || formInfo.partner?.name?.length < 5){
          setError({partner:{name: 'Preencha o nome completo.'}});
          setErrorMessage("Preencha o nome completo do sócio.")
          return;
        }
        if(!formInfo.partner?.motherName?.includes(" ") || formInfo.partner?.motherName?.length < 5){
          setError({partner:{motherName: 'Preencha o nome da mãe completo.'}});
          setErrorMessage("Preencha o nome completo da mãe do sócio.")
          return;
        }

        // CPF xxx.xxx.xxx-xx
        if(formInfo.partner?.document?.length < 14){
          setError({partner:{motherName: 'CPF Inválido.'}});
          setErrorMessage("CPF Inválido.")
          return;
        }

        if(!validateCPF(formInfo.partner?.document?.replace(/[/,.,-\s]/g, ""))){
          setError({partner:{motherName: 'CPF Inválido.'}});
          setErrorMessage("CPF Inválido.")
          return;
        }
        // BirthDate xxxx/xx/xx
        if(formInfo.partner?.birthDate?.length < 10){
          setError({partner:{birthDate: 'Data de Nascimento incompleta.'}});
          setErrorMessage('Data de Nascimento incompleta.')
          return;
        }

        if(!validateDate(formInfo.partner?.birthDate)){
          setError({partner:{birthDate: 'Data de Nascimento inválida.'}});
          setErrorMessage('Data de Nascimento inválida.')
          return;
        }

        if(formInfo.partner?.isPep === undefined){
          setError({partner:{isPep: 'Selecione se o sócio é ou não PEP.'}});
          setErrorMessage('Selecione se o sócio é ou não PEP.')
          return;
        }



    if(formInfo.partner.address.postalCode.length < 9){
      setError({address: {postalCode: "Preencha o CEP completo."}});
      setErrorMessage("Preencha o CEP completo.")
      return;
    }

    if(formInfo.partner.address.state.length !== 2){
      setError({address: {state: "Estado Inválido."}});
      setErrorMessage("Estado Inválido")
      return;
    }


    setLoading(true);
    try {

      setLoading(false);
      setStep(6);
    } catch (err) {
      const myError = serviceError(err);
      setLoading(false);
      setErrorMessage(myError.friend);
    }
  }

  async function handlePasswords() {

    setError({} as IFormError);

        // // Password Validations
    const pass = formInfo.password;
    const pass2 = formInfo.password2;
    if(pass.length < 8) {
      setError({password: 'A senha deve ter pelo menos 8 dígitos.'});
      return;
    }

    if(!/[A-Z]/.test(pass) || !/[a-z]/.test(pass) || !/\d/.test(pass)) {
      setError({password: 'A senha deve ter pelo uma letra maiúscula, uma letra minúscula e um número.'});
      return;
    }

    if (pass !== pass2) {
      setError({password2: 'As senhas não estão iguais.'});
      return;
    }

    if(formInfo.pin.length !== 4) {
      setError({...error, pin: 'O PIN deve ter 4 dígitos.'});
      return;
    }

    if(["1234", "2345", "3456", "4567", "5678", "6789", "4321", "5432", "6543", "7654", "8765", "9876", "3210", "0123"].includes(formInfo.pin)) {
      setError({...error, pin: 'O PIN não pode ser composto de números consecutivos.'});
      return;
    }
    setLoading(true);

    try {

      const raw = {
        "type": "company",
        "name": formInfo.name,
        "document": formInfo.document?.replace(/[/,.,-\s]/g, ""),
        "phone": formInfo.phone?.replace(/[-,\s,(,)]/g, ""),
        "email": formInfo.email,
        "password": formInfo.password,
        "pin": formInfo.pin,
        "legal_name": formInfo.legalName,
        "legal_nature": getLegalNature(formInfo.establishmentType),
        "establishment_date": formInfo.establishmentDate?.split("/").reverse().join("-"),
        "establishment_type": formInfo.establishmentType,
        "main_cnae": formInfo.mainCnae,
        "revenue": Number(formInfo.revenue?.replace(/[/,R$ .,-\s]/g, ""))/100,
        "address": {
         "postal_code": formInfo.address?.postalCode?.replace(/-/g, ""),
              "street": formInfo.address?.street,
              "number": formInfo.address?.number,
              "extra": formInfo.address?.extra || "",
              "neighborhood": formInfo.address?.neighborhood,
              "city": formInfo.address?.city,
              "state": formInfo.address?.state
        },
        "partners": [
          {
            "is_pep": formInfo.partner?.isPep,
            "name": formInfo.partner?.name,
            "document": formInfo.partner?.document?.replace(/[/,.,-\s]/g, ""),
            "phone": formInfo.phone?.replace(/[-,\s,(,)]/g, ""), //same as company
            "email": formInfo.email, //same as company
            "birth_date": formInfo.partner?.birthDate?.split("/").reverse().join("-"),
            "mother_name": formInfo.partner?.motherName,
            "address": {
              "postal_code": formInfo.partner?.address?.postalCode?.replace(/-/g, ""),
              "street": formInfo.partner?.address?.street,
              "number": formInfo.partner?.address?.number,
              "extra": formInfo.partner?.address?.extra || "",
              "neighborhood": formInfo.partner?.address?.neighborhood,
              "city": formInfo.partner?.address?.city,
              "state": formInfo.partner?.address?.state
            }
          }
        ]
      }
      console.log('raw', raw);

      await service.post("users/companies", raw, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      await signIn({document:raw.document, password:raw.password});
      // await signIn({document:"35763423000148", password:"12345678aA"});
      setLoading(false);
      setStep(7);
    } catch (err) {
      const myError = serviceError(err);
      setLoading(false);
      setErrorMessage(myError.friend);
      // @ts-ignore
      console.log(err.message)
    }
  }

  const handleFileSelect = async (event: any) => {
    setErrorMessage('');
    const formData = new FormData();
    formData.append("file", event.target?.files[0]);


    setLoading(true);
    try {
      // let parseType = '';
      // if (typeDoc === 'SELFIE') {
      //   parseType = 'documentFace';
      // }
      // else if (typeDoc === 'DRIVER_LICENSE_FRONT' || typeDoc === 'IDENTITY_CARD_FRONT') {
      //   parseType = 'documentFront';
      // }
      // else if (typeDoc === 'IDENTITY_CARD_VERSE' || typeDoc === 'DRIVER_LICENSE_VERSE') {
      //   parseType = 'documentBack';
      // } else {
      //   parseType = 'documentBack';
      // }


      const { data: response } = await axios({
        method: "post",
        url: `https://corebank.ajiopay.com.br/users/documents?type=${typeDoc}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data", authorization: `Bearer ${token}` },
      });



      if (response?.status && (response.status === 'under_review')) {
        // mensagem de aguardar até 1 dia útil.
        setStep(99);
        setSuccess(true);
      }

      if (response?.status && (response.status === 'denied')) {
        // mensagem de não foi possível abrir a conta.
        setStep(66);
        setSuccess(true);
      }

      if (typeDoc === 'SELFIE') {
        setTypeDoc('DOCS');
      }

      if (typeDoc === 'DRIVER_LICENSE_FRONT') {
        setTypeDoc('DRIVER_LICENSE_VERSE');
      }

      if (typeDoc === 'IDENTITY_CARD_FRONT') {
        setTypeDoc('IDENTITY_CARD_VERSE');
      }

      let companyType = user.company_type;

      if (typeDoc === 'IDENTITY_CARD_VERSE' || typeDoc === 'DRIVER_LICENSE_VERSE') {

        // If it's MEI/EI we only need a specific document
        if(companyType === "MEI")
          setTypeDoc('CCMEI');

        if(companyType === "EI" || companyType === "EIRELI")
          setTypeDoc('EI_REGISTRATION_REQUIREMENT');

        if(companyType === "LTDA")
          setTypeDoc('ARTICLES_OF_ASSOCIATION');

        if(companyType === "SA")
          setTypeDoc('COMPANY_BYLAWS');
      }

      setLoading(false);
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setErrorMessage(error.friend);
    }

  }

  return (
    <Container>
      <ContentWrapper>
        <WrapperLeft></WrapperLeft>
        <WrapperRight>
          <GoBackButtonWrapper onClick={handleGoBack}>
            <ArrowLeft />
            voltar
          </GoBackButtonWrapper>
          <WrapperContent>
            <Title>
              Você está a poucos passos de uma nova{" "}
              <strong>experiência financeira</strong>
            </Title>
           {step === 1 && (
            <>
            <Text>
              Todos os dados a seguir devem ser obrigatoriamente do
              empresa.
            </Text>
            <WrapperForm onSubmit={(e)=>{e.preventDefault(); handleOneSubmit();}}>
              <FormInput
                label="Nome Fantasia"
                required
                error={error.name || ""}
                onChanged={(e)=> setFormInfo({...formInfo, name: e.target.value})}
              />
              <FormInput
                label="Razão Social"
                required
                error={error.legalName || ""}
                onChanged={(e)=> setFormInfo({...formInfo, legalName: e.target.value})}
              />
              <FormInput
                label="CNPJ"
                required
                mask="99.999.999/9999-99"
                error={error.document || ""}
                onChanged={(e)=> setFormInfo({...formInfo, document: e.target.value})}
               />
              <FormInput
                label="E-mail"
                required
                error={error.email || ""}
                onChanged={(e)=> setFormInfo({...formInfo, email: e.target.value})}
                />
              <FormInput
                label="Telefone"
                required
                mask="(99) 99999-9999"
                error={error.phone || ""}
                onChanged={(e)=> setFormInfo({...formInfo, phone: e.target.value})}
                />

                {!!errorMessage && (
                <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">
                        {errorMessage}
                      </p>
                    </div>
                  </div>
                </div>
                )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
           )}

           {step === 2 && (
            <>
            <Text>
              Todos os dados a seguir devem ser obrigatoriamente da empresa.
            </Text>
            <WrapperForm onSubmit={(e)=>{e.preventDefault(); handleTwoSubmitStepPJ();}}>
            <FormSelect
                label="Tipo Societário"
                placeholder="Selecione uma opção"
                options={["MEI", "LTDA", "SA", "EIRELI", "COOP", "OUTROS"]}
                error={error.establishmentType || ""}
                onChanged={(e)=> setFormInfo({...formInfo, establishmentType: e.target.value})}
              />
              {/* {(formInfo.establishmentType === "DEMAIS"||
              formInfo.establishmentType === "SA"
              ) && <FormSelect
                label="Natureza Jurídica"
                placeholder="Selecione uma opção"
                options={getLegalNatureOptions(formInfo.establishmentType)}
                error={error.legalNature || ""}
                onChanged={(e)=> setFormInfo({...formInfo, legalNature: e.target.value})}
              />} */}
              <FormInput
                label="CNAE Principal"
                required
                maxLength={7}
                error={error.mainCnae || ""}
                onChanged={(e)=> setFormInfo({...formInfo, mainCnae: e.target.value.replace(/[/,.,-\s]/g, "")})}
                />
              <FormInput
                label="Data de fundação"
                mask="99/99/9999"
                required
                error={error.establishmentDate || ""}
                onChanged={(e)=> setFormInfo({...formInfo, establishmentDate: e.target.value})}
                />
               <FormInput
                label="Receita Mensal Média"
                mask="currency"
                required
                onChanged={(e)=> setFormInfo({...formInfo, revenue: e.target.value})}
                error={error.revenue || ""}
              />

                {!!errorMessage && (
                <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">
                        {errorMessage}
                      </p>
                    </div>
                  </div>
                </div>
                )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
           )}

          {step === 3 && (
            <>
            <Text>
              Todos os dados a seguir devem ser obrigatoriamente da
              empresa.
            </Text>
            <WrapperForm onSubmit={(e)=>{e.preventDefault(); handleCep();}}>
            <FormInput
              label="CEP"
              mask="99999-999"
              required
              error={error.address?.postalCode || ""}
              onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, postalCode: e.target.value}})}
              />

              {!!errorMessage && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {errorMessage}
                    </p>
                  </div>
                </div>
              </div>
              )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
           )}

           {step === 4 && (
            <>
            <Text>
              Todos os dados a seguir devem ser obrigatoriamente do
              titular.
            </Text>
            <WrapperForm onSubmit={(e)=>{e.preventDefault(); handleAddress();}}>
            <FormInput
              label="CEP"
              mask="99999-999"
              required
              error={error.address?.postalCode || ""}
              value={formInfo.address.postalCode}
              onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, postalCode: e.target.value}})}
              />

              <div className="flex gap-5 mb-[27px]">
              <FormInput
              nomargin
              label="Cidade"
              required
              error={error.address?.city || ""}
              value={formInfo.address.city}
              onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, city: e.target.value}})}
              />

            <FormInput
              nomargin
              label="Estado"
              value={formInfo.address.state}
              error={error.address?.state || ""}
              required
              maxLength={2}
              onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, state: e.target.value}})}
              />
              </div>

              <FormInput
                label="Bairro"
                value={formInfo.address.neighborhood}
                error={error.address?.neighborhood || ""}
                required
                onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, neighborhood: e.target.value}})}
              />

            <FormInput
              label="Rua"
              error={error.address?.street || ""}
              value={formInfo.address.street}
              maxLength={39}
              required
              onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, street: e.target.value}})}
            />

              <div className="flex gap-5 mb-[27px]">
              <FormInput
                nomargin
                label="Número"
                error={error.address?.number || ""}
                value={formInfo.address.number}
                required
                onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, number: e.target.value}})}
              />

              <FormInput
                nomargin
                label="Complemento"
                value={formInfo.address.extra}
                onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, extra: e.target.value}})}
              />
              </div>


              {!!errorMessage && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {errorMessage}
                    </p>
                  </div>
                </div>
              </div>
              )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
          )}

          {step === 5 && (
                        <>
                        <Text>
                          Informe os dados de um sócio majoritário ou administrador.
                        </Text>
                        <WrapperForm onSubmit={(e)=>{e.preventDefault(); handlePartner();}}>
                        <FormInput
                label="Nome do Sócio"
                required
                error={error.partner?.name || ""}
                onChanged={(e)=> setFormInfo({...formInfo, partner:{...formInfo.partner, name: e.target.value}})}
              />
              <FormInput
                label="CPF"
                required
                mask="999.999.999-99"
                error={error.partner?.document || ""}
                onChanged={(e)=> setFormInfo({...formInfo, partner:{...formInfo.partner, document: e.target.value}})}
               />

              <FormInput
                label="Data de nascimento"
                required
                mask="99/99/9999"
                error={error.partner?.birthDate || ""}
                onChanged={(e)=> setFormInfo({...formInfo, partner:{...formInfo.partner, birthDate: e.target.value}})}
                />
              <FormInput
                label="Nome da mãe"
                required
                error={error.partner?.motherName || ""}
                onChanged={(e)=> setFormInfo({...formInfo, partner:{...formInfo.partner, motherName: e.target.value}})}
                />
              <FormRadio
                label="O sócio é PEP?"
                options={["Não", "Sim"]}
                value=""
                error={error.partner?.isPep || ""}
                onChanged={(e)=> setFormInfo({...formInfo, partner:{...formInfo.partner, isPep: e.target.value === "Sim" ? true: false}})}
              />
                <FormInput
                  label="CEP"
                  mask="99999-999"
                  required
                  error={error.partner?.address?.postalCode || ""}
                  value={formInfo.partner?.address?.postalCode}
                  onChanged={(e)=> setFormInfo({...formInfo, partner:{...formInfo.partner, address: {...formInfo.partner?.address, postalCode: e.target.value}}})}
                />

              <FormInput
              nomargin
              label="Cidade"
              required
              error={error.partner?.address?.city || ""}
                  value={formInfo.partner?.address?.city}
                  onChanged={(e)=> setFormInfo({...formInfo, partner:{...formInfo.partner, address: {...formInfo.partner?.address, city: e.target.value}}})}
              />

            <FormInput
              nomargin
              label="Estado"
              required
              maxLength={2}
              error={error.partner?.address?.state || ""}
              value={formInfo.partner?.address?.state}
              onChanged={(e)=> setFormInfo({...formInfo, partner:{...formInfo.partner, address: {...formInfo.partner?.address, state: e.target.value}}})}
            />

              <FormInput
                label="Bairro"
                required
                error={error.partner?.address?.neighborhood || ""}
              value={formInfo.partner?.address?.neighborhood}
              onChanged={(e)=> setFormInfo({...formInfo, partner:{...formInfo.partner, address: {...formInfo.partner?.address, neighborhood: e.target.value}}})}
              />

            <FormInput
              label="Rua"
              required
              error={error.partner?.address?.street || ""}
              value={formInfo.partner?.address?.street}
              maxLength={39}
              onChanged={(e)=> setFormInfo({...formInfo, partner:{...formInfo.partner, address: {...formInfo.partner?.address, street: e.target.value}}})}
            />

              <FormInput
                nomargin
                label="Número"
                required
                error={error.partner?.address?.number || ""}
                value={formInfo.partner?.address?.number}
                onChanged={(e)=> setFormInfo({...formInfo, partner:{...formInfo.partner, address: {...formInfo.partner?.address, number: e.target.value}}})}
              />

              <FormInput
                nomargin
                label="Complemento"
                value={formInfo.partner?.address?.extra}
                onChanged={(e)=> setFormInfo({...formInfo, partner:{...formInfo.partner, address: {...formInfo.partner?.address, extra: e.target.value}}})}
              />

                          {!!errorMessage && (
                          <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                              </div>
                              <div className="ml-3">
                                <p className="text-sm text-red-700">
                                  {errorMessage}
                                </p>
                              </div>
                            </div>
                          </div>
                          )}

                          <WrapperButton>
                            <Button type="submit">
                              <Loading isLoading={loading} />
                              Continuar
                            </Button>
                          </WrapperButton>
                        </WrapperForm>
                        </>
          )}

            {step === 6 && (
            <>
            <Text>
              Defina suas credencias de acesso.
            </Text>
            <WrapperForm onSubmit={(e)=>{e.preventDefault(); handlePasswords();}}>
            <FormInput
                label="SENHA"
                type="password"
                error={error.password || ""}
                onChanged={(e)=> setFormInfo({...formInfo, password: e.target.value})}
              />
              <FormInput
                label="REPITA A SENHA"
                type="password"
                error={error.password2 || ""}
                onChanged={(e)=> setFormInfo({...formInfo, password2: e.target.value})}
              />

            <FormInput
                maxLength={4}
                label="PIN (senha de operações bancárias)"
                type="password"
                error={error.pin || ""}
                onChanged={(e)=> setFormInfo({...formInfo, pin: e.target.value})}
              />

              {!!errorMessage && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {errorMessage}
                    </p>
                  </div>
                </div>
              </div>
              )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
           )}

          {step === 7 && (
            <>
            {typeDoc === 'SELFIE' && (
              <>
                <Title>
                Selfie do sócio cadastrado.
              </Title>
              <Text>
                Precisamos que envie uma foto de rosto.
                Não se preocupe ela é apenas para identificação e segurança.
              </Text>
              </>
            )}

            {typeDoc !== 'SELFIE' && (
              <>
              {(typeDoc === 'DRIVER_LICENSE_FRONT' || typeDoc === 'IDENTITY_CARD_FRONT') && (
                <>
                  <Title>
                  Documentos do sócio cadastrado.
                </Title>
                <Text>
                  Precisamos que envie agora uma foto da FRENTE do documento escolhido anteriormente (CNH ou RG).
                  Não se preocupe, é apenas para identificação e segurança.
                </Text>
                </>
              )}

              {(typeDoc === 'DRIVER_LICENSE_VERSE' || typeDoc === 'IDENTITY_CARD_VERSE') && (
                <>
                  <Title>
                  Documentos do sócio cadastrado.
                </Title>
                <Text>
                Precisamos que envie agora uma foto do VERSO do documento escolhido anteriormente (CNH ou RG).
                  Não se preocupe, é apenas para identificação e segurança.
                </Text>
                </>
              )}

            {(typeDoc !== 'DRIVER_LICENSE_VERSE' &&  typeDoc !== 'IDENTITY_CARD_VERSE' && typeDoc !== 'DRIVER_LICENSE_FRONT' && typeDoc !== 'IDENTITY_CARD_FRONT') && (
                <>
                  <Title>
                  Documentos da Empresa
                </Title>
                <Text>
                  Precisamos que envie fotos de documentos da empresa.
                  Não se preocupe, é apenas para sua identificação e segurança.
                </Text>
                </>
              )}
              </>
            )}
            <WrapperForm onSubmit={(e) => e.preventDefault()}>
              {typeDoc === 'DOCS' && (
                <>
                  <Button type="button" style={{ marginBottom: '30px' }} onClick={() => setTypeDoc('DRIVER_LICENSE_FRONT')}>
                      CNH
                  </Button>
                  <Button type="button" onClick={() => setTypeDoc('DRIVER_LICENSE_FRONT')}>
                      RG
                  </Button>
                </>
              )}
              {typeDoc !== 'DOCS' && (
                <>
                 {typeDoc === 'IDENTITY_CARD_VERSE' && (
                  <h3 className="text-2xl mb-5">Ótimo! agora precisamos do verso</h3>
                 )}
                <div className="flex justify-center items-center w-full">
                    <label htmlFor="dropzone-file" className={`${loading ? 'bg-gray-600' : ''} flex flex-col justify-center items-center w-full h-40 rounded-lg border-2 border-dashed cursor-pointer border-cyan-400`}>
                        <div className="flex flex-col justify-center items-center pt-5 pb-6">
                            {loading ? (
                              <>
                              <Loading isLoading />
                            <p className="text-white mt-5">Analisando imagem...</p></>
                            ): (
                              <>
                                <svg className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Clique para fazer upload</span> ou arraste e solte</p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                              </>
                            )}
                        </div>
                        <input id="dropzone-file" type="file" className="hidden" onChange={handleFileSelect} />
                    </label>
                </div>
                <div className="flex justify-center mt-2">
                {typeDoc === 'CCMEI' && (
                    <p>
                      <ul>
                        <li>Envie o Certificado da Condição do Microempreendedor Individual (CCMEI)</li>
                      </ul>
                  </p>
                  )}
                   {typeDoc === 'ARTICLES_OF_ASSOCIATION' && (
                    <p>
                      <ul>
                        <li>Envie o contrato social</li>
                      </ul>
                  </p>
                  )}
                   {typeDoc === 'COMPANY_BYLAWS' && (
                    <p>
                      <ul>
                        <li>Envie o Estatuto da Empresa</li>
                      </ul>
                  </p>
                  )}
                  {typeDoc === 'EI_REGISTRATION_REQUIREMENT' && (
                    <p>
                    <ul>
                      <li>Envie o documento Requerimento de Empresário</li>
                    </ul>
                  </p>)}
                  {typeDoc === 'DRIVER_LICENSE_FRONT' && (
                    <p>
                      <ul>
                        <li>Envie a CNH com boa definição</li>
                        <li><strong>Não</strong> envie seu documento embaçado</li>
                      </ul>
                  </p>
                  )}
                  {typeDoc === 'DRIVER_LICENSE_VERSE' && (
                     <p>
                     <ul>
                       <li>Envie o verso da CNH com boa definição</li>
                       <li><strong>Não</strong> envie seu documento embaçado</li>
                     </ul>
                   </p>
                  )}

                  {typeDoc === 'IDENTITY_CARD_FRONT' && (
                    <p>
                    <ul>
                      <li>Envie o RG com boa definição</li>
                      <li><strong>Não</strong> envie seu documento embaçado</li>
                    </ul>
                  </p>
                  )}
                  {typeDoc === 'IDENTITY_CARD_VERSE' && (
                    <p>
                    <ul>
                      <li>Envie o verso do RG com boa definição</li>
                      <li><strong>Não</strong> envie seu documento embaçado</li>
                    </ul>
                  </p>
                  )}

                  {typeDoc === 'SELFIE' && (
                    <p>
                      <ul>
                        <li>Escolha uma foto com boa iluminação</li>
                        <li><strong>Não</strong> envie fotos com acessórios que escondem o seu rosto</li>
                        <li><strong>Não</strong> envie fotos com mais de uma pessoa</li>
                      </ul>
                    </p>
                  )}
                </div>
                </>

              )}
              {!!errorMessage && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5 mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {errorMessage}
                    </p>
                  </div>
                </div>
              </div>
              )}
            </WrapperForm>
            </>
          )}

          {step === 66 && (
            <Text>
              Infelizmente não foi possível abrir sua conta neste momento.
            </Text>
          )}

           {step === 99 && (
            <Text>
              Pronto! Agora é só esperar a análise dos dados. Em até 1 dia você irá receber a resposta em seu e-mail.
            </Text>
          )}
          </WrapperContent>
        </WrapperRight>
      </ContentWrapper>
    </Container>
  );
};

