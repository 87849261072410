import { useEffect, useState } from "react";
import {
    Container,
    Header,
    OrdersWrapper,
    Order,
    IconWrapper,
    InfoOrder,
    Balance,
    OrderDetails,
    BalanceDetails,
    Type,
    Name,
} from "./styles";

import { BsArrowUpShort, BsArrowDownShort } from 'react-icons/bs'

import { normalizeName } from "../../utils/normelizaName";
import { currencyFormat } from "../../utils/currencyFormat";
import { format } from "date-fns";
import brasilLocale from 'date-fns/locale/pt-BR';

import { Link } from "react-router-dom";
import { Skeleton } from "../skeleton";
import { service } from "../../services/ajio";
import { ExtractItem, extractDescription } from "../../utils/extractDescription";



export const Orders = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<ExtractItem[]>([]);
    useEffect(() => {
       service.get(`/transactions?page=1`).then(({data}) => {
        setItems(data.transactions.data.slice(0, 5));
        setLoading(false);
      });

    }, []);

    function getType(type: string): string {
      if (type === 'in') {
        return "Crédito";
      }

      if (type === 'out') {
        return "Débito";
      }
      return type;
    }



    return (
        <Container>
            <Header>
                <span>Últimos lançamentos</span>
                <Link to="/extract">
                  <p>Ver extrato completo</p>
                </Link>

            </Header>
            <OrdersWrapper>

            {!loading && !items.length && (
              <div>
                <div className="text-center">
                  <h3 className="mt-2 text-sm font-medium text-gray-900">Nenhum lançamento para o mês de <strong>{format(new Date(), 'LLLL', { locale: brasilLocale })}</strong></h3>
                  <p className="mt-1 text-sm text-gray-500">Faça sua primeira transação</p>
                  <div className="mt-6">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Transferir
                    </button>
                  </div>
                </div>
              </div>
            )}

          <Skeleton isActive={loading} repeat={4} />


            {!loading && items.map(item => (
              <Order key={item.id}>
                  <OrderDetails>
                      <IconWrapper>
                          {item.direction === 'in' ? (
                            <BsArrowUpShort color="var(--green)" size={21} />
                          ) : (
                            <BsArrowDownShort color="var(--boxText)" size={21} />
                          )}
                      </IconWrapper>
                      <InfoOrder>
                          <Type>{getType(item.direction)}</Type>
                          <Name className="truncate">{normalizeName(extractDescription(item))}</Name>
                      </InfoOrder>
                  </OrderDetails>
                  <BalanceDetails>
                      <Balance credit={item.direction === 'in'}>
                        {currencyFormat(Number(item.amount))}
                      </Balance>
                  </BalanceDetails>
              </Order>
            ))}
            </OrdersWrapper>
        </Container>
    )
}
