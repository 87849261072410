import { AnimatePresence } from "framer-motion";
import React, { useMemo, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { documentFormatted } from "../../utils/documentFormatted";
import { BellMessage } from "../bellMessage";
import { Button } from "../button";
import { ClientInfoAccount } from "../clientInfoAccount";
import IconHeader from "../IconHeader";
import {
  BellIcon,
  // BellNotifications,
  BellWrapper,
  Box,
  BoxNotifications,
  BoxWrapper,
  ButtonBell,
  ClientInfo,
  CloseIcon,
  Container,
  DashboardInfo,
  HeaderWrapper,
  // OtherAccounts,
  SectionInfo,
  SectionTitle,
  SidebarButton,
  SidebarIcon,
  // BankMoney,
  // HomeIcon,
  // ReceiptIcon,
  ArrowPageRightIcon,
  // ArrowUpIcon,
  ButtonWrapper,
  OtherAccounts,
  BellNotifications,
} from "./styles";
import { IconSwitch } from "./SwitchIcon";

type props = {
  handleSidebarFunction: () => void;
  sidebarIsActive: boolean;
};

export const Header: React.FC<props> = ({
  // children,
  handleSidebarFunction,
  sidebarIsActive,
}) => {
  const routes = useLocation();
  const { account } = useAuth();
  const navigate = useNavigate();

  const routesNames = useMemo(() => {
    return [
      {
        path: "/",
        name: "Início",
        iconName: "HomeIcon",
      },
      {
        path: "/receipt",
        name: "Extrato da conta",
        iconName: "ReceiptIcon",
      },
      {
        path: "/extract",
        name: "Extrato da conta",
        iconName: "ReceiptIcon",
      },
      {
        path: "/deposit",
        name: "Depositar",
        iconName: "DepositIcon",
      },
      {
        path: "/deposit/tedordoc",
        name: "Depositar",
        subname: "TED ou DOC",
        iconName: "DepositIcon",
      },
      {
        path: "/deposit/invoice",
        name: "Depositar",
        subname: "Boleto",
        iconName: "DepositIcon",
      },
      {
        path: "/deposit/invoice/confirm",
        name: "Depositar",
        subname: "Boleto",
        iconName: "DepositIcon",
      },
      {
        path: "/deposit/invoices-list",
        name: "Depositar",
        subname: "Boleto",
        iconName: "DepositIcon",
      },
      {
        path: "/charge",
        name: "Gestão de cobrança",
        iconName: "ChargeIcon",
      },
      {
        path: "/pix",
        name: "Área Pix",
        iconName: "PixIconSVG",
      },
      {
        path: "/pix/list",
        name: "Área Pix",
        iconName: "PixIconSVG",
      },
      {
        path: "/pix/transfer",
        name: "Área pix",
        iconName: "PixIconSVG",
      },
      {
        path: "/pix/mykeys",
        name: "Minhas chaves",
        // subname: "Minhas chaves",
        iconName: "PixIconSVG",
      },
      {
        path: "/pix/mykeys/new",
        name: "Nova chave",
        // subname: "Minhas chaves",
        iconName: "PixIconSVG",
      },
      {
        path: "/paybills",
        name: "Pagar contas",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/invoice",
        name: "Pagar contas",
        subname: "Pagamento de boleto",

        iconName: "BillIcon",
      },
      {
        path: "/paybills/info-invoice",
        name: "Pagar contas",
        subname: "Pagamento de boleto",

        iconName: "BillIcon",
      },
      {
        path: "/paybills/ticket",
        name: "Pagar contas",
        subname: "Pagamento de boleto",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/confirm-invoice",
        name: "Pagar contas",
        subname: "Pagamento de boleto",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/subscribe",
        name: "Pagar contas",
        subname: "Cadastrar favorecido",
        iconName: "BillIcon",
      },
      {
        path: "/paybills/schedule-payments",
        name: "Pagar contas",
        subname: "Pagamentos agendados",
        iconName: "BillIcon",
      },
      {
        path: "/extract",
        name: "Pagar contas",
        subname: "Pagamento de boleto",
        iconName: "ChargeIcon",
      },
      {
        path: "/transfer",
        name: "Transferir TED/DOC",
        iconName: "BankMoney",
      },
      {
        path: "/transfer/confirm",
        name: "Transferir TED/DOC",
        iconName: "BankMoney",
      },
      {
        path: "/transfer/list",
        name: "Transferir TED/DOC",
        iconName: "BankMoney",
      },
      {
        path: "/transfer/subscribe",
        name: "Transferir TED/DOC",
        subname: "",
        iconName: "BankMoney",
      },
      {
        path: "/tax",
        name: "Tarifas",
        iconName: "TaxIcon",
      },
    ];
  }, []);

  const currentRouteInfo = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    return routesNames.find((route) => {
      if (routes.pathname === route.path) {
        return route;
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes.pathname]);

  const [BellBoxMessageIsActive, setBoxMessageIsActive] = useState(false);
  const [otherAccountsIsActive, setOtherAccountsIsActive] = useState(true);

  const HandleBoxMessageToggle = () => {
    setBoxMessageIsActive((state) => !state);
  };

  const HandleOtherAccountsToggle = () => {
    setOtherAccountsIsActive((state) => !state);
  };

  return (
    <Container>
      <SidebarButton onClick={handleSidebarFunction}>
        {sidebarIsActive ? <CloseIcon /> : <SidebarIcon />}
      </SidebarButton>
      <HeaderWrapper>
        <SectionInfo>
          <IconHeader Icon={IconSwitch(currentRouteInfo?.iconName!)} />

          <SectionTitle>{currentRouteInfo?.name}</SectionTitle>

          {currentRouteInfo?.subname && (
            <div className="flex justify-center items-center">
              <ArrowPageRightIcon />
              <SectionTitle>{currentRouteInfo?.subname}</SectionTitle>
            </div>
          )}
        </SectionInfo>
        <DashboardInfo>

          {/* {currentRouteInfo?.path === "/extract" && (
            <ButtonWrapper>
              <Button Icon={ArrowUpIcon} title="Exportar" />
            </ButtonWrapper>
          )} */}
          {currentRouteInfo?.path === "/pix/mykeys" && (
            <ButtonWrapper className="md:hidden">

              <Button title="Cadastrar nova chave" handleFunction={() => {
                navigate("/pix/mykeys/new")
              }} />
            </ButtonWrapper>
          )}

          {currentRouteInfo?.path === "/charge" && (
            <ButtonWrapper>
              <Button title="Nova cobrança" handleFunction={() => {
                navigate("/deposit/invoice")
              }} />
            </ButtonWrapper>
          )}

          {/* {currentRouteInfo?.path === "/extract" && (
            <ButtonWrapper>
              <Button title="Exportar"/>
            </ButtonWrapper>
          )} */}

          {/* <BellWrapper>
            <ButtonBell onClick={HandleBoxMessageToggle}>
              <BellIcon />
            </ButtonBell>
            <BellNotifications>
              <p>2</p>
            </BellNotifications>
            <AnimatePresence>
              {BellBoxMessageIsActive && (
                <BoxWrapper
                  initial={{ opacity: 0, y: "-10%" }}
                  animate={{ opacity: 1, y: "0%" }}
                  exit={{ opacity: 0, y: "-10%" }}
                  transition={{ duration: 0.2 }}
                >
                  <Box>
                    <span></span>
                    <BoxNotifications>
                      <BellMessage
                        title="Nenhuma mensagem"
                        link="#"
                        desc="Nenhuma mensagem pendente"
                        // Você recebeu uma transferência de ROSANGELA ANTUNES FERREIRA no valor de R$ 1.245,23
                      />
                    </BoxNotifications>
                  </Box>
                </BoxWrapper>
              )}
            </AnimatePresence>
          </BellWrapper> */}
          <ClientInfo>
            <ClientInfoAccount
              handleFunction={HandleOtherAccountsToggle}
              isSelected={true}
              name={account.name}
              id={documentFormatted(account.document)}
            />
            <AnimatePresence>
              {otherAccountsIsActive && (
                <OtherAccounts
                  initial={{ opacity: 0, y: "-10px" }}
                  animate={{ opacity: 1, y: "0px" }}
                  exit={{ opacity: 0, y: "-10px" }}
                  transition={{ duration: 0.2, type: "tween" }}
                >
                  {/* <ClientInfoAccount
                    isSelected={false}
                    name={"Martin Ferramentas"}
                    id={"32.241.521/0001-23"}
                  />
                  <ClientInfoAccount
                    isSelected={false}
                    name={"Martin Ferramentas"}
                    id={"32.241.521/0001-23"}
                  /> */}
                </OtherAccounts>
              )}
            </AnimatePresence>
          </ClientInfo>
        </DashboardInfo>
      </HeaderWrapper>
    </Container>
  );
};
