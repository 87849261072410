const colors = {
  background: '#F7F6F8',
  'gray-light-line': '#E3E3E3',
  "gray-light-text": '#A3A3A3',
  "gray-light-order": '#B5B7BD',
  "cian-dark": 'rgba(0, 0, 0, .3)',
  "gray": '#142249',
  "gray-light-05": 'rgba(20, 34, 73, .05)',
  "gray-background": '#0E1833',
  "gray-dark": '#282A3A',
  "cian": '#38D2D9',
  "white": '#fff',
  "white-light": 'rgba(255, 255, 255, .3)',
  "white-light-5": 'rgba(255, 255, 255, .05)',
  "green": '#49D294',
  "red": '#E74C3C',
  "line": 'rgba(255, 255, 255, .1)',
  "modalTitle": '#777986',
  "yellow": '#F6BC4D',
  "confirmBackground": '#EAEAEA',
  "borderInput": '#3B4C7E',
  "lineBorder": '#707070',
  "box": '#F2F2F2',
  "boxText": '#999999',
  "loginBackground": '#142249',
  "colorLogin": "#fff",
};
const setup = [
  {
    name: 'Cone Banx',
    host: "conebanx.com.br",
    named: 'vfbank',
    client_id: "ecd982cc-f3b8-4331-a072-88f6366fa41b",
    // client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    colors: {
      ...colors,
      "cian-dark": "rgba(255, 255, 255, 0.8)",
      "gray": '#696a6e',
      // "gray": 'white',
      "cian": '#e2af0d',
      // "yellow": '#e2af0d',
      "green": '#e2af0d',
      "loginBackground": '#696a6e',
    },
    signin: require('../assets/sign-in-cone.png'),
    whiteLogo: {
      "image": require('../assets/clients/conebanx/logo/white.png'),
      "width": 145.8,
      "height": 41.8,
      "maxWidth": 218.7,
      "maxHeight": 62.7,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/conebanx/logo/dark.png'),
      "width": 145.8,
      "height": 41.8,
      "maxWidth": 218.7,
      "maxHeight": 62.7,
      "objectFit": "contain"
    },
  },
  {
    name: 'Nine Bank',
    host: "iglobank.stric.com.br",
    named: 'iglobank',
    client_id: "ecd982cc-f3b8-4331-a072-88f6366fa41b",
    // client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    colors: {
      ...colors,
      "gray": '#151518',
      "cian": '#5f83fb',
      "yellow": '#5f83fb',
      "green": '#5f83fb',
      "loginBackground": '#151518',
    },
    signin: require('../assets/1-signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/iglobank/logo/white.png'),
      "width": 889 * 0.20,
      "height": 319 * 0.20,
      "maxWidth": 889 * 0.25,
      "maxHeight": 319 * 0.25,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/iglobank/logo/dark.png'),
      "width": 889 * 0.20,
      "height": 319 * 0.20,
      "maxWidth": 889 * 0.25,
      "maxHeight": 319 * 0.25,
      "objectFit": "contain"
    },
  },
  {
    name: 'Nine Bank',
    host: "ninebank.stric.com.br",
    named: 'iglobank',
    client_id: "ecd982cc-f3b8-4331-a072-88f6366fa41b",
    // client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    colors: {
      ...colors,
      "gray": '#151518',
      "cian": '#5f83fb',
      "yellow": '#5f83fb',
      "green": '#5f83fb',
      "loginBackground": '#151518',
    },
    signin: require('../assets/1-signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/iglobank/logo/white.png'),
      "width": 889 * 0.20,
      "height": 319 * 0.20,
      "maxWidth": 889 * 0.25,
      "maxHeight": 319 * 0.25,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/iglobank/logo/dark.png'),
      "width": 889 * 0.20,
      "height": 319 * 0.20,
      "maxWidth": 889 * 0.25,
      "maxHeight": 319 * 0.25,
      "objectFit": "contain"
    },
  },

];


export default setup;
