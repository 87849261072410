import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';

import { service as api, CommonHeaderProperties } from '../services/ajio';
import { useClient } from './client';

interface Account {
  id: string
  refId: string
  status: string
  branch_number: string
  account_number: string
  account_digit: any
  alias_status: string
  balance: number
  created_at: string
  updated_at: string
  user_id: string
  name: string;
  isPool: boolean;
  document: string;
  bank: string;
  accountNumber: string;
  branch: string;
  account: any;
  email: string;
  accountDigit: string;

}

interface User {
  id: string
  name: string
  document: string
  email: string
  phone: string
  role: string
  status: string
  refId: string
  type: string
  created_at: string
  updated_at: string
  company_type: string | null;
}

interface AuthState {
  token: string;
  account: Account;
  user: User;
}

interface SignInCredentials {
  document: string;
  password: string;
}

interface AuthContextData {
  account: Account;
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signInWithToken(token: string): Promise<void>;
  signOut(): void;
  refresh: () => void;
  refreshAccount: () => void;
  checkStep: (account: Account, token: string) => Promise<boolean | void>;
  // updateBalance(balance: number): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
  children: React.ReactNode;
}


export function AuthProvider({ children }: AuthProviderProps) {
  const { client } = useClient();
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@stricv2:token');
    const account = localStorage.getItem('@stricv2:account') as string;
    const user = localStorage.getItem('@stricv2:user') as string;


    if (token && account && user) {
      const accountData = JSON.parse(account);
      const userData = JSON.parse(user);

      api.defaults.headers = {
        Authorization: `Bearer ${token}`,
        account: accountData.id,
      } as CommonHeaderProperties;

      return { token, account: accountData, user: userData };
    }

    return {} as AuthState;
  });

  async function checkStep(data: Account, token: string) {
    if (data.status === 'waiting_documents') {
      window.localStorage.setItem('@stricv2:status', 'waiting_documents');
      window.localStorage.setItem('@stricv2:id', token);
      localStorage.removeItem('@stricv2:token');
      localStorage.removeItem('@stricv2:account');
      sessionStorage.removeItem('@stricv2:token');
      sessionStorage.removeItem('@stricv2:account');

      localStorage.setItem('@stricv2:token', token);
      return;
    }

    if (data.status === 'under_review') {
      window.localStorage.setItem('@stricv2:status', 'under_review');
      window.localStorage.setItem('@stricv2:id', token);
      localStorage.removeItem('@stricv2:token');
      localStorage.removeItem('@stricv2:account');
      sessionStorage.removeItem('@stricv2:token');
      sessionStorage.removeItem('@stricv2:account');

      localStorage.setItem('@stricv2:token', token);
      return;
    }

    // const type = data.type === 'PJ' ? 'pj' : 'pf';

  //   if (type === 'pj') {
  //       if (data.status === 'WAITING_TOKEN') {
  //         window.localStorage.setItem('@stricv2:status', 'WAITING_TOKEN');
  //         window.localStorage.setItem('@stricv2:id', token);
  //         localStorage.removeItem('@stricv2:token');
  //         localStorage.removeItem('@stricv2:account');
  //         // window.location.href = `/create-account/?type=${type}`;
  //         return;
  //       }


  //       if (data.status === 'WAITING_FLOW') {
  //         window.localStorage.setItem('@stricv2:status', 'WAITING_FLOW');
  //         window.localStorage.setItem('@stricv2:id', token);
  //         localStorage.removeItem('@stricv2:token');
  //         localStorage.removeItem('@stricv2:account');
  //         // window.location.href = `/create-account/?type=${type}`;
  //         return;
  //       }

  //       if (data.status === 'WAITING_PARTNERS') {
  //         window.localStorage.setItem('@stricv2:status', 'WAITING_PARTNERS');
  //         window.localStorage.setItem('@stricv2:id', token);
  //         localStorage.removeItem('@stricv2:token');
  //         localStorage.removeItem('@stricv2:account');
  //         // window.location.href = `/create-account/?type=${type}`;
  //         return;
  //       }

  //       if (data.status === 'WAITING_ADDRESS') {
  //         window.localStorage.setItem('@stricv2:status', 'WAITING_ADDRESS');
  //         window.localStorage.setItem('@stricv2:id', token);
  //         localStorage.removeItem('@stricv2:token');
  //         localStorage.removeItem('@stricv2:account');
  //         // window.location.href = `/create-account/?type=${type}`;
  //         return;
  //       }

  //       if (data.status === 'WAITING_DOCUMENTS') {
  //         window.localStorage.setItem('@stricv2:status', 'WAITING_DOCUMENTS');
  //         window.localStorage.setItem('@stricv2:id', token);
  //         localStorage.removeItem('@stricv2:token');
  //         localStorage.removeItem('@stricv2:account');
  //         sessionStorage.removeItem('@stricv2:token');
  //         sessionStorage.removeItem('@stricv2:account');
  //         // window.location.href = `/create-account/?type=${type}`;
  //         return;
  //       }

  //       if (data.status === 'WAITING_VALIDATION') {
  //         window.localStorage.setItem('@stricv2:status', 'WAITING_VALIDATION');
  //         window.localStorage.setItem('@stricv2:id', token);
  //         localStorage.removeItem('@stricv2:token');
  //         localStorage.removeItem('@stricv2:account');
  //         sessionStorage.removeItem('@stricv2:token');
  //         sessionStorage.removeItem('@stricv2:account');
  //         // window.location.href = `/create-account/?type=${type}`;
  //         return;
  //       }

  //       if (data.status === 'WAITING_VALIDATION') {
  //         window.localStorage.setItem('@stricv2:status', 'WAITING_VALIDATION');
  //         localStorage.removeItem('@stricv2:token');
  //         localStorage.removeItem('@stricv2:account');
  //         localStorage.removeItem('@stricv2:token');
  //         localStorage.removeItem('@stricv2:account');
  //         sessionStorage.removeItem('@stricv2:token');
  //         sessionStorage.removeItem('@stricv2:account');
  //         // window.location.href = `/create-account/?type=${type}`;
  //         return;
  //       }
  //   }

  //   if (type === 'pf') {
  //     if (data.status === 'WAITING_TOKEN') {
  //       window.localStorage.setItem('@stricv2:status', 'WAITING_TOKEN');
  //       window.localStorage.setItem('@stricv2:id', token);
  //       localStorage.removeItem('@stricv2:token');
  //       localStorage.removeItem('@stricv2:account');
  //       // window.location.href = `/create-account/?type=${type}`;
  //       return;
  //     }


  //     if (data.status === 'WAITING_FLOW') {
  //       window.localStorage.setItem('@stricv2:status', 'WAITING_FLOW');
  //       window.localStorage.setItem('@stricv2:id', token);
  //       localStorage.removeItem('@stricv2:token');
  //       localStorage.removeItem('@stricv2:account');
  //       // window.location.href = `/create-account/?type=${type}`;
  //       return;
  //     }

  //     if (data.status === 'WAITING_PARTNERS') {
  //       window.localStorage.setItem('@stricv2:status', 'WAITING_PARTNERS');
  //       window.localStorage.setItem('@stricv2:id', token);
  //       localStorage.removeItem('@stricv2:token');
  //       localStorage.removeItem('@stricv2:account');
  //       // window.location.href = `/create-account/?type=${type}`;
  //       return;
  //     }

  //     if (data.status === 'WAITING_ADDRESS') {
  //       window.localStorage.setItem('@stricv2:status', 'WAITING_ADDRESS');
  //       window.localStorage.setItem('@stricv2:id', token);
  //       localStorage.removeItem('@stricv2:token');
  //       localStorage.removeItem('@stricv2:account');
  //       // window.location.href = `/create-account/?type=${type}`;
  //       return;
  //     }

  //     if (data.status === 'WAITING_DOCUMENTS') {
  //       window.localStorage.setItem('@stricv2:status', 'WAITING_DOCUMENTS');
  //       window.localStorage.setItem('@stricv2:id', token);
  //       localStorage.removeItem('@stricv2:token');
  //       localStorage.removeItem('@stricv2:account');
  //       sessionStorage.removeItem('@stricv2:token');
  //       sessionStorage.removeItem('@stricv2:account');
  //       // window.location.href = `/create-account/?type=${type}`;
  //       return;
  //     }

  //     if (data.status === 'WAITING_VALIDATION') {
  //       window.localStorage.setItem('@stricv2:status', 'WAITING_VALIDATION');
  //       window.localStorage.setItem('@stricv2:id', token);
  //       localStorage.removeItem('@stricv2:token');
  //       localStorage.removeItem('@stricv2:account');
  //       sessionStorage.removeItem('@stricv2:token');
  //       sessionStorage.removeItem('@stricv2:account');
  //       // window.location.href = `/create-account/?type=${type}`;
  //       return;
  //     }

  //     if (data.status === 'WAITING_VALIDATION') {
  //       window.localStorage.setItem('@stricv2:status', 'WAITING_VALIDATION');
  //       localStorage.removeItem('@stricv2:token');
  //       localStorage.removeItem('@stricv2:account');
  //       localStorage.removeItem('@stricv2:token');
  //       localStorage.removeItem('@stricv2:account');
  //       sessionStorage.removeItem('@stricv2:token');
  //       sessionStorage.removeItem('@stricv2:account');
  //       // window.location.href = `/create-account/?type=${type}`;
  //       return;
  //     }
  // }


    return true;
  }

  async function getAccount() {
    const token = window.localStorage.getItem('@stricv2:token')
    const account = window.localStorage.getItem('@stricv2:account')
    const user = window.localStorage.getItem('@stricv2:user')

    if (token && account && user) {
      try {
        api.defaults.headers = {
          Authorization: `Bearer ${token}`,
        } as CommonHeaderProperties;

        const { data } = await api.get('/me');

        await checkStep(data.account, token);

        setData({ token, account: data.account, user: data.user  });
      } catch(err) {
        signOut();
      }
    }
  }

  function refresh() {
    setTimeout(() => {
      console.log('refresh.1');
      getAccount();
    }, 2000);

    setTimeout(() => {
      console.log('refresh.2');
      getAccount();
    }, 60000);
  }

  function refreshAccount() {
    setInterval(() => {
      console.log('refresh.account');
      getAccount();
    }, 600000);
  }


  useEffect(() => {
    console.log('refresh.effect');
    getAccount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = useCallback(async ({ document, password }) => {
    window.localStorage.removeItem('@stricv2:status');
    window.localStorage.removeItem('@stricv2:id');

    const response = await api.post('/sessions', {
      document,
      password,
    }, {
      headers: {
        client: client.client_id, // "d2afc12c-bc27-440c-96c2-88e006c92ab2"
      }
    });


    const { token, user, account } = response.data;

    api.defaults.headers = {
      Authorization: `Bearer ${token}`,
    } as CommonHeaderProperties;


    localStorage.setItem('@stricv2:token', token);
    localStorage.setItem('@stricv2:account', JSON.stringify(account));
    localStorage.setItem('@stricv2:user', JSON.stringify(user));

    api.defaults.headers = {
      Authorization: `Bearer ${token}`,
      // account: data.id,
    } as CommonHeaderProperties;

    await checkStep(account, token);

    setData({ token, account, user });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const signInWithToken = useCallback(async (token) => {
    window.localStorage.removeItem('@stricv2:status');
    window.localStorage.removeItem('@stricv2:id');

    api.defaults.headers = {
      Authorization: `Bearer ${token}`,
    } as CommonHeaderProperties;


    const { data: user } = await api.get('/users')

    const { data: responseApiAccount } = await api.get("/accounts");

    localStorage.setItem('@stricv2:token', token);
    localStorage.setItem('@stricv2:account', JSON.stringify(data));


    await checkStep(user, token);

    setData({ token, account: responseApiAccount, user, });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@stricv2:token');
    localStorage.removeItem('@stricv2:account');
    localStorage.removeItem('@stricv2:user');

    setData({} as AuthState);
  }, []);


  return (
    <AuthContext.Provider
      value={{ checkStep: checkStep, account: data.account, signIn, signInWithToken, signOut, refresh, refreshAccount, user: data.user }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}



