import React, { InputHTMLAttributes, forwardRef, ForwardRefRenderFunction, Fragment } from "react";
import InputMask from 'react-input-mask';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { FieldError } from "react-hook-form";
import IntlCurrencyInput from "react-intl-currency-input";


import { Container, Label } from "./styles";
interface Props extends InputHTMLAttributes<HTMLInputElement>{
  label: string
  mask?: string;
  error?: string;
  nomargin?: boolean;
  onChanged: (e: any, number?: number, masked?: string) => void;
}

interface PropsSelect {
  label: string
  placeholder?: string;
  options: string[];
  error?: string;
  nomargin?: boolean;
  onChanged: (e: any, number?: number, masked?: string) => void;
}

interface PropsRadio {
  label: string
  options: string[];
  value: string;
  error?: string;
  nomargin?: boolean;
  onChanged: (e: any, number?: number, masked?: string) => void;
}


const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const InputBase
  = ({ label, mask, error, nomargin = false, onChanged, ...rest}:Props) => {

    return (
      <div style={{ marginBottom: 27, width: '100%' }}>
      <Container nomargin={nomargin}>
      <Label>{label}</Label>
      {mask ? (
        <>
          {mask !== 'currency' ? (
            <InputMask maskChar="" mask={mask}  type="text" {...rest} onChange={onChanged}/>
          ) : (
            <IntlCurrencyInput
              currency="BRL"
              config={currencyConfig}
              {...rest}
              onChange={onChanged}
          />
          )}
        </>
      ): (
        <input {...rest} onChange={(e) => onChanged(e)}/>
      )}
    </Container>
    {!!error && (
              <div>
                {!!error && (
                  <p className="text-sm text-red-600" id="email-error">
                    {error}
                  </p>
                )}
              </div>
            )}
      </div>
    )
  }

  const InputSelect = ({ label, placeholder="Selecione uma opção", options, error, nomargin = false, onChanged, ...rest}:PropsSelect) => {


    return (
      <div style={{ marginBottom: 27 }}>
      <Container nomargin={nomargin}>
      <Label>{label}</Label>
        <select {...rest} onChange={onChanged}>
        <option value="">{placeholder}</option>
        {options.map((opt:string)=>{
          return <option key={opt} value={opt}>{opt}</option>
        })}
          </select>
    </Container>
    {!!error && (
              <div>
                {!!error && (
                  <p className="text-sm text-red-600" id="email-error">
                    {error}
                  </p>
                )}
              </div>
            )}
      </div>
    )
  }

  const InputRadio = ({ label, options, value, error, nomargin = true, onChanged, ...rest}:PropsRadio) => {

    return (
      <div style={{ marginBottom: 27 }}>
      <Container nomargin={nomargin}>
      <Label>{label}</Label>
        <div className="flex items-center justify-start">
         {options.map((opt)=>{
            return(
              <Fragment key={opt}>
                <input defaultChecked={false} name={label} type="radio" value={opt} className="mr-2 mb-1" onChange={onChanged}/>
                <label className="mr-6">{opt}</label>
              </Fragment>
            )
          }
          )}
        </div>

    </Container>
    {!!error && (
              <div>
                {!!error && (
                  <p className="text-sm text-red-600" id="email-error">
                    {error}
                  </p>
                )}
              </div>
            )}
      </div>
    )
  }

export const FormInput = InputBase;

export const FormSelect = InputSelect;

export const FormRadio = InputRadio;

