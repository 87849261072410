import React, { FormEvent, useEffect, useState } from "react";
import {
  Container,
  ContentWrapper,
  GoBackButtonWrapper,
  Text,
  Title,
  WrapperContent,
  WrapperLeft,
  WrapperRight,
  WrapperButton,
  WrapperForm,
  Button,
  ResendButton,
} from "./styles";



import { ReactComponent as ArrowLeft } from "../../assets/LParrowLeft.svg";
import {FormInput, FormRadio, FormSelect } from "../../components/LPFormButton2";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";

import { api, parseError } from "../../services/api";
import { ExclamationIcon } from "@heroicons/react/solid";
import { addHours, format } from "date-fns";
import { useClient } from "../../hooks/client";
import axios from "axios";
import { useNotification } from "../../hooks/notification";
import { isValid } from 'date-fns'
import { useAuth } from "../../hooks/auth";
import { professions } from "../../utils/professions";
import { service, serviceError } from "../../services/ajio";
import { validateCellphone, validateCPF, validateDate, validateEmail } from "../../utils/validations";


type IFormInfo = {
  "isPep": boolean;
  "isForeign": false; //fixed to false because we don't acept foreigners yet
  "name": string;
  "document": string;
  "email": string;
  "birthDate": string;
  "motherName": string;
  "phone": string;
  "profession": string;
  "identityType": "rg"; //fixed to RG
  "identityNumber": string;
  "identityIssuedAt": string;
  "monthlyIncome": string;
  "password": string;
  "password2": string;
  "emailValidationToken": string;
  "pin": string;
  "address": {
    "street": string;
    "number": string;
    "extra": string;
    "neighborhood": string;
    "city": string;
    "state": string; //only the UF code
    "postalCode": string; //only numbers
  }
}

type IFormError = {
  "isPep"?: string;
  "isForeign"?: string; //fixed to false because we don't acept foreigners yet
  "name"?: string;
  "document"?: string;
  "email"?: string;
  "birthDate"?: string;
  "motherName"?: string;
  "phone"?: string;
  "profession"?: string;
  "identityType"?: string; //fixed to RG
  "identityNumber"?: string;
  "identityIssuedAt"?: string;
  "monthlyIncome"?: string;
  "password"?: string;
  "password2"?: string;
  "emailValidationToken"?: string;
  "pin"?: string;
  "address"?: {
    "street"?: string;
    "number"?: string;
    "extra"?: string;
    "neighborhood"?: string;
    "city"?: string;
    "state"?: string; //only the UF code
    "postalCode"?: string; //only numbers
  }
}

interface IndividualProps {
  status: string | null;
}

export default function Individuals ({ status }: IndividualProps) {
  const { signOut, signIn } = useAuth()
  const location = useLocation();
  const { client } = useClient();
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const { setOpenNotification } = useNotification();


  const [formInfo, setFormInfo] = useState<IFormInfo>({} as IFormInfo);
  const [error, setError] = useState<IFormError>({} as IFormError); // field specific errors
  const [errorMessage, setErrorMessage] = useState("") // general form erros
  const [typeDoc, setTypeDoc] = useState('SELFIE');

  const [step, setStep] = useState(() => {
    const statusStorage = status || localStorage.getItem('@stricv2:status');

    if (statusStorage) {
      if (statusStorage === 'WAITING_TOKEN') {
        return 2;
      }

      if (statusStorage === 'WAITING_FLOW') {
        return 3;
      }

      if (statusStorage === 'WAITING_PARTNERS') {
        return 3;
      }

      if (statusStorage === 'WAITING_ADDRESS') {
        return 5;
      }

      if (statusStorage === 'waiting_documents') {
        return 6;
      }

      if (statusStorage === 'denied') {
        return 66;
      }

      if (statusStorage === 'under_review') {
        return 99;
      }

    }

    return 1;
  });

  const [sucess, setSuccess] = useState(() => {
    const statusStorage = localStorage.getItem('@stricv2:status');

    if (statusStorage === 'under_review' || statusStorage === 'under_review') {
      return true;
    }

    return false;
  });

  const [token, setToken] = useState(() => {
    const tokenStorage = localStorage.getItem('@stricv2:id');
    if (tokenStorage) {
      return tokenStorage;
    }
    return '';
  });


  const navigation = useNavigate();
  // const { setOpenNotification } = useNotification();
  const handleGoBack = async () => {
    await signOut();
    navigation("/signin");
  };

  useEffect(() => {
    if (!location.search || location.search === null || location.search === '') {
      navigation('/signin');
    }
  }, [location.search, navigation]);



  const handleOneSubmit = async () => {

    setError({} as IFormError);

    // Name and Mother Name Validations
    if(!formInfo.name.includes(" ") || formInfo.name.length < 5){
      setError({name: 'Preencha o nome completo.'});
      return;
    }

    if(!formInfo.motherName.includes(" ") || formInfo.motherName.length < 5){
      setError({motherName: 'Preencha o nome completo.'});
      return;
    }

    // CPF xxx.xxx.xxx-xx
    if(formInfo.document.length < 14){
      setError({document: 'CPF Inválido.'});
      return;
    }

    if(!validateCPF(formInfo.document.replace(/[/,.,-\s]/g, ""))){
      setError({document: 'CPF Inválido.'});
      return;
    }

    // Email
    if(formInfo.email.length < 6){
      setError({email: 'Preencha o email.'});
      return;
    }

    if(!validateEmail(formInfo.email)){
      setError({email: 'Email Inválido.'});
      return;
    }

    // Phone (xx) xxxxx-xxxx
    if(formInfo.phone.length < 14){
      setError({phone: 'Celular Inválido.'});
      return;
    }

    if(!validateCellphone(formInfo.phone.replace(/[-,\s,(,)]/g, ""))){
      setError({phone: 'Celular Inválido.'});
      return;
    }

    // BirthDate xxxx/xx/xx
    if(formInfo.birthDate.length < 10){
      setError({birthDate: 'Data de Nascimento incompleta.'});
      return;
    }
    if(!validateDate(formInfo.birthDate)){
      setError({birthDate: 'Preencha a data de emissão completa.'});
      return;
    }



    setLoading(true);

    try {

      setLoading(false);
      setStep(2);

    } catch (err) {
      console.log(err);
      const error = serviceError(err);
      const errorAll = err as any;
      setLoading(false);
      if (error.code === 'document.already' || error.code === 'email.already') {
        setOpenNotification({title:'Alerta', notification:'Nós já nos conhecemos!\n\nIdentificamos que você já possui um vínculo com a gente.\n\nPara continuar, utilize sua senha e comece a desfrutar de todas as vantangens.', type: "warning"});
      } else {
        setOpenNotification({title: "Erro", notification: error.friend || errorAll?.response?.data?.message?.[0], type: "error"});
      }
    }
  }

  const handleTwoSubmitStepPF = async () => {

    setError({} as IFormError);
    // Identity validation xx.xxx.xxx
    if(formInfo.identityNumber.length < 10){
      setError({...error, identityNumber: 'Preencha o número da identidade completo.'});
      return;
    }

    if(formInfo.identityIssuedAt.length < 10){
      setError({...error, identityIssuedAt: 'Preencha a data de emissão completa.'});
      return;
    }

    if(!validateDate(formInfo.identityIssuedAt)){
      setError({...error, identityIssuedAt: 'Data inválida.'});
      return;
    }

    if(formInfo.monthlyIncome === "R$ 0,00" || formInfo.monthlyIncome === undefined){
      setError({...error, monthlyIncome: 'Preencha sua renda mensal.'});
      return;
    }


    if(formInfo.isPep === undefined){
      setError({...error, isPep: 'Selecione se você é ou não PEP.'});
      return;
    }



    setLoading(true);
    try {

      setLoading(false);
      setStep(3);
    } catch (err) {
      console.log(err);
      const error = serviceError(err);
      setLoading(false);
      setErrorMessage(error.friend);
    }
  }


  async function handleCep() {

    setError({} as IFormError);

    // xx.xxx-xxx
    if(formInfo.address.postalCode.length < 9){
      setError({...error, address: {...formInfo.address, postalCode: "Preencha o CEP completo."}});
      return;
    }

    const rawPostalCode = formInfo.address.postalCode.replace(/-/g, "")
    if (rawPostalCode.length >= 8) {
      setLoading(true);

      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${rawPostalCode}/json/`);
        console.log(data);
        if (data) {
          setFormInfo({...formInfo, address:{...formInfo.address, street: data.logradouro, neighborhood: data.bairro, city: data.localidade, state: data.uf}})
        }
        setLoading(false);
        setStep(4);
      } catch (err) {
        setLoading(false);
        setStep(4);
      }
    }
  }


  async function handleAddress() {

    setError({} as IFormError);

    if(formInfo.address.postalCode.length < 9){
      setError({...error, address: {...formInfo.address, postalCode: "Preencha o CEP completo."}});
      return;
    }

    if(formInfo.address.state.length !== 2){
      setError({...error, address: {...formInfo.address, state: "Estado Inválido."}});
      return;
    }

    if(formInfo.address.state.length !== 2){
      setError({...error, address: {...formInfo.address, state: "Estado Inválido."}});
      return;
    }

    setLoading(true);
    try {

      setLoading(false);
      setStep(5);
    } catch (err) {
      const myError = serviceError(err);
      setLoading(false);
      setErrorMessage(myError.friend);
    }
  }

  async function handlePasswords() {

    setError({} as IFormError);

        // // Password Validations
    const pass = formInfo.password;
    const pass2 = formInfo.password2;
    if(pass.length < 8) {
      setError({...error, password: 'A senha deve ter pelo menos 8 dígitos.'});
      return;
    }

    if(!/[A-Z]/.test(pass) || !/[a-z]/.test(pass) || !/\d/.test(pass)) {
      setError({...error, password: 'A senha deve ter pelo uma letra maiúscula, uma letra minúscula e um número.'});
      return;
    }

    if (pass !== pass2) {
      setError({...error, password2: 'As senhas não estão iguais.'});
      return;
    }

    if(formInfo.pin.length !== 4) {
      setError({...error, pin: 'O PIN deve ter 4 dígitos.'});
      return;
    }

    if(["1234", "2345", "3456", "4567", "5678", "6789"].includes(formInfo.pin)) {
      setError({...error, pin: 'O PIN não pode ser composto de números consecutivos.'});
      return;
    }

    setLoading(true);
    try {
      const raw = {
        "is_pep": formInfo.isPep,
        "is_foreign": false,
        "name": formInfo.name,
        "document": formInfo.document.replace(/[/,.,-\s]/g, ""),
        "email": formInfo.email,
        "birth_date": formInfo.birthDate.split("/").reverse().join("-"),
        "mother_name": formInfo.motherName,
        "phone": formInfo.phone.replace(/[-,\s,(,)]/g, ""),
        // @ts-ignore
        "profession_id": professions[formInfo.profession],
        "identity_type": "rg", //fixed to RG
        "identity_number": formInfo.identityNumber.replace(/[/,.,-\s]/g, ""),
        "identity_issued_at": formInfo.identityIssuedAt.split("/").reverse().join("-"),
        "monthly_income": Number(formInfo.monthlyIncome.replace(/[/,R$ .,-\s]/g, ""))/100,
        "password": formInfo.password,
        "pin": formInfo.pin,
        "address": {
          "street": formInfo.address.street,
          "number": formInfo.address.number,
          "extra": formInfo.address.extra || "",
          "neighborhood": formInfo.address.neighborhood,
          "city": formInfo.address.city,
          "state": formInfo.address.state, //only the UF code
          "postal_code": formInfo.address.postalCode.replace(/-/g, ""), //only numbers
        }
      }
      await service.post("users/individuals", raw, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      await signIn({document:raw.document, password:raw.password});
      setLoading(false);
      setStep(6);
    } catch (err) {
      const myError = serviceError(err);
      setLoading(false);
      setErrorMessage(myError.friend);
    }
  }

  const handleFileSelect = async (event: any) => {
    setErrorMessage('');
    const formData = new FormData();
    formData.append("file", event.target?.files[0]);


    setLoading(true);
    try {
      // let parseType = '';
      // if (typeDoc === 'SELFIE') {
      //   parseType = 'documentFace';
      // }
      // else if (typeDoc === 'DRIVER_LICENSE_FRONT' || typeDoc === 'IDENTITY_CARD_FRONT') {
      //   parseType = 'documentFront';
      // }
      // else if (typeDoc === 'IDENTITY_CARD_VERSE' || typeDoc === 'DRIVER_LICENSE_VERSE') {
      //   parseType = 'documentBack';
      // } else {
      //   parseType = 'documentBack';
      // }


      const { data: response } = await axios({
        method: "post",
        url: `https://corebank.ajiopay.com.br/users/documents?type=${typeDoc}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data", authorization: `Bearer ${token}` },
      });



      if (response?.status && (response.status === 'under_review')) {
        // mensagem de aguardar até 1 dia útil.
        setStep(99);
        setSuccess(true);
      }

      if (response?.status && (response.status === 'denied')) {
        // mensagem de não foi possível abrir a conta.
        setStep(66);
        setSuccess(true);
      }

      if (typeDoc === 'SELFIE') {
        setTypeDoc('DOCS');
      }

      if (typeDoc === 'DRIVER_LICENSE_FRONT') {
        setTypeDoc('DRIVER_LICENSE_VERSE');
      }

      if (typeDoc === 'IDENTITY_CARD_FRONT') {
        setTypeDoc('IDENTITY_CARD_VERSE');
      }

      // if (typeDoc === 'IDENTITY_CARD_VERSE' || typeDoc === 'DRIVER_LICENSE_VERSE') {
      //   setTypeDoc('DOCUMENT_CARD');
      // }

      // if (typeDoc === 'DOCUMENT_CARD') {
      //   setTypeDoc('ARTICLES_OF_ASSOCIATION');
      // }

      // if (typeDoc === 'ARTICLES_OF_ASSOCIATION') {
      //   setTypeDoc('PROOF_ADDRESS');
      // }


      setLoading(false);
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setErrorMessage(error.friend);
    }

  }

  return (
    <Container>
      <ContentWrapper>
        <WrapperLeft></WrapperLeft>
        <WrapperRight>
          <GoBackButtonWrapper onClick={handleGoBack}>
            <ArrowLeft />
            voltar
          </GoBackButtonWrapper>
          <WrapperContent>
            <Title>
              Você está a poucos passos de uma nova{" "}
              <strong>experiência financeira</strong>
            </Title>
           {step === 1 && (
            <>
            <Text>
              Todos os dados a seguir devem ser obrigatoriamente do
              titular.
            </Text>
            <WrapperForm onSubmit={(e)=>{e.preventDefault(); handleOneSubmit();}}>
              <FormInput
                label="NOME"
                required
                error={error.name || ""}
                onChanged={(e)=> setFormInfo({...formInfo, name: e.target.value})}
              />
              <FormInput
                label="CPF"
                required
                mask="999.999.999-99"
                error={error.document || ""}
                onChanged={(e)=> setFormInfo({...formInfo, document: e.target.value})}
               />
              <FormInput
                label="E-MAIL"
                required
                error={error.email || ""}
                onChanged={(e)=> setFormInfo({...formInfo, email: e.target.value})}
                />
              <FormInput
                label="CELULAR"
                required
                mask="(99) 99999-9999"
                error={error.phone || ""}
                onChanged={(e)=> setFormInfo({...formInfo, phone: e.target.value})}
                />

              <FormInput
                label="DATA DE NASCIMENTO"
                required
                mask="99/99/9999"
                error={error.birthDate || ""}
                onChanged={(e)=> setFormInfo({...formInfo, birthDate: e.target.value})}
                />
              <FormInput
                label="NOME DA MÃE"
                required
                error={error.motherName || ""}
                onChanged={(e)=> setFormInfo({...formInfo, motherName: e.target.value})}
                />
                {!!errorMessage && (
                <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">
                        {errorMessage}
                      </p>
                    </div>
                  </div>
                </div>
                )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
           )}

           {step === 2 && (
            <>
            <Text>
              Todos os dados a seguir devem ser obrigatoriamente do titular.
            </Text>
            <WrapperForm onSubmit={(e)=>{e.preventDefault(); handleTwoSubmitStepPF();}}>
              <FormInput
                label="Número RG"
                // mask="99.999.999-0"
                required
                error={error.identityNumber || ""}
                onChanged={(e)=> setFormInfo({...formInfo, identityNumber: e.target.value})}
              />
             <FormInput
                label="Data da emissão do RG"
                mask="99/99/9999"
                required
                error={error.identityIssuedAt || ""}
                onChanged={(e)=> setFormInfo({...formInfo, identityIssuedAt: e.target.value})}
              />

            <FormSelect
                label="Profissão"
                placeholder="Selecione sua profissão"
                options={Object.keys(professions)}
                error={error.profession || ""}
                onChanged={(e)=> setFormInfo({...formInfo, profession: e.target.value})}
              />
            <FormInput
                label="Renda Mensal"
                mask="currency"
                required
                onChanged={(e)=> setFormInfo({...formInfo, monthlyIncome: e.target.value})}
                error={error.monthlyIncome || ""}
              />
            <FormRadio
                label="Você é PEP?"
                options={["Não", "Sim"]}
                value=""
                onChanged={(e)=> setFormInfo({...formInfo, isPep: e.target.value === "Sim" ? true : false})}
                error={error.isPep || ""}
              />
                {!!errorMessage && (
                <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">
                        {errorMessage}
                      </p>
                    </div>
                  </div>
                </div>
                )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
           )}

          {step === 3 && (
            <>
            <Text>
              Todos os dados a seguir devem ser obrigatoriamente do
              titular.
            </Text>
            <WrapperForm onSubmit={(e)=>{e.preventDefault(); handleCep();}}>
            <FormInput
              label="CEP"
              mask="99999-999"
              required
              error={error.address?.postalCode || ""}
              onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, postalCode: e.target.value}})}
              />

              {!!errorMessage && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {errorMessage}
                    </p>
                  </div>
                </div>
              </div>
              )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
           )}

           {step === 4 && (
            <>
            <Text>
              Todos os dados a seguir devem ser obrigatoriamente do
              titular.
            </Text>
            <WrapperForm onSubmit={(e)=>{e.preventDefault(); handleAddress();}}>
            <FormInput
              label="CEP"
              mask="99999-999"
              required
              error={error.address?.postalCode || ""}
              value={formInfo.address.postalCode}
              onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, postalCode: e.target.value}})}
              />

              <div className="flex gap-5 mb-[27px]">
              <FormInput
              nomargin
              label="Cidade"
              required
              error={error.address?.city || ""}
              value={formInfo.address.city}
              onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, city: e.target.value}})}
              />

            <FormInput
              nomargin
              label="Estado"
              value={formInfo.address.state}
              error={error.address?.state || ""}
              required
              maxLength={2}
              onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, state: e.target.value}})}
              />
              </div>

              <FormInput
                label="Bairro"
                value={formInfo.address.neighborhood}
                error={error.address?.neighborhood || ""}
                required
                onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, neighborhood: e.target.value}})}
              />

            <FormInput
              label="Rua"
              error={error.address?.street || ""}
              value={formInfo.address.street}
              maxLength={39}
              required
              onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, street: e.target.value}})}
            />

              <div className="flex gap-5 mb-[27px]">
              <FormInput
                nomargin
                label="Número"
                error={error.address?.number || ""}
                value={formInfo.address.number}
                required
                onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, number: e.target.value}})}
              />

              <FormInput
                nomargin
                label="Complemento"
                value={formInfo.address.extra}
                onChanged={(e)=> setFormInfo({...formInfo, address:{...formInfo.address, extra: e.target.value}})}
              />
              </div>


              {!!errorMessage && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {errorMessage}
                    </p>
                  </div>
                </div>
              </div>
              )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
          )}

            {step === 5 && (
            <>
            <Text>
              Defina suas credencias de acesso.
            </Text>
            <WrapperForm onSubmit={(e)=>{e.preventDefault(); handlePasswords();}}>
            <FormInput
                label="SENHA"
                type="password"
                error={error.password || ""}
                onChanged={(e)=> setFormInfo({...formInfo, password: e.target.value})}
              />
              <FormInput
                label="REPITA A SENHA"
                type="password"
                error={error.password2 || ""}
                onChanged={(e)=> setFormInfo({...formInfo, password2: e.target.value})}
              />

            <FormInput
                maxLength={4}
                label="PIN (senha de operações bancárias)"
                type="password"
                error={error.pin || ""}
                onChanged={(e)=> setFormInfo({...formInfo, pin: e.target.value})}
              />

              {!!errorMessage && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {errorMessage}
                    </p>
                  </div>
                </div>
              </div>
              )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
           )}

          {step === 6 && (
            <>
            {typeDoc === 'SELFIE' && (
              <>
                <Title>
                É hora da selfie!
              </Title>
              <Text>
                Precisamos que envie uma foto do seu rosto para ninguém se passar por você.
                Não se preocupe ela é apenas para sua identificação e segurança.
              </Text>
              </>
            )}

            {typeDoc !== 'SELFIE' && (
              <>
              {(typeDoc === 'DRIVER_LICENSE_FRONT' || typeDoc === 'IDENTITY_CARD_FRONT') && (
                <>
                  <Title>
                  É hora dos documentos
                </Title>
                <Text>
                  Precisamos que envie agora uma foto da FRENTE do seu documento escolhido anteriormente (CNH ou RG).
                  Não se preocupe, é apenas para sua identificação e segurança.
                </Text>
                </>
              )}

              {(typeDoc === 'DRIVER_LICENSE_VERSE' || typeDoc === 'IDENTITY_CARD_VERSE') && (
                <>
                  <Title>
                  É hora dos documentos
                </Title>
                <Text>
                Precisamos que envie agora uma foto do VERSO do seu documento escolhido anteriormente (CNH ou RG).
                  Não se preocupe, é apenas para sua identificação e segurança.
                </Text>
                </>
              )}

            {(typeDoc !== 'DRIVER_LICENSE_VERSE' &&  typeDoc !== 'IDENTITY_CARD_VERSE' && typeDoc !== 'DRIVER_LICENSE_FRONT' && typeDoc !== 'IDENTITY_CARD_FRONT') && (
                <>
                  <Title>
                  É hora dos documentos
                </Title>
                <Text>
                  Precisamos que envie fotos do seu documento (CNH ou RG).
                  Não se preocupe, é apenas para sua identificação e segurança. Escolhe abaixo qual documento vai enviar.
                </Text>
                </>
              )}
              </>
            )}
            <WrapperForm onSubmit={(e) => e.preventDefault()}>
              {typeDoc === 'DOCS' && (
                <>
                  <Button type="button" style={{ marginBottom: '30px' }} onClick={() => setTypeDoc('DRIVER_LICENSE_FRONT')}>
                      CNH
                  </Button>
                  <Button type="button" onClick={() => setTypeDoc('DRIVER_LICENSE_FRONT')}>
                      RG
                  </Button>
                </>
              )}
              {typeDoc !== 'DOCS' && (
                <>
                 {typeDoc === 'IDENTITY_CARD_VERSE' && (
                  <h3 className="text-2xl mb-5">Ótimo! agora precisamos do verso</h3>
                 )}
                <div className="flex justify-center items-center w-full">
                    <label htmlFor="dropzone-file" className={`${loading ? 'bg-gray-600' : ''} flex flex-col justify-center items-center w-full h-40 rounded-lg border-2 border-dashed cursor-pointer border-cyan-400`}>
                        <div className="flex flex-col justify-center items-center pt-5 pb-6">
                            {loading ? (
                              <>
                              <Loading isLoading />
                            <p className="text-white mt-5">Analisando imagem...</p></>
                            ): (
                              <>
                                <svg className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Clique para fazer upload</span> ou arraste e solte</p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                              </>
                            )}
                        </div>
                        <input id="dropzone-file" type="file" className="hidden" onChange={handleFileSelect} />
                    </label>
                </div>
                <div className="flex justify-center mt-2">
                {typeDoc === 'DOCUMENT_CARD' && (
                    <p>
                      <ul>
                        <li>Envie o cartão CNPF com boa definição</li>
                      </ul>
                  </p>
                  )}
                   {typeDoc === 'ARTICLES_OF_ASSOCIATION' && (
                    <p>
                      <ul>
                        <li>Envie o contrato social</li>
                      </ul>
                  </p>
                  )}
                   {typeDoc === 'PROOF_ADDRESS' && (
                    <p>
                      <ul>
                        <li>Envie o comprovante de endereço</li>
                      </ul>
                  </p>
                  )}
                  {typeDoc === 'DRIVER_LICENSE_FRONT' && (
                    <p>
                      <ul>
                        <li>Envie a CNH com boa definição</li>
                        <li><strong>Não</strong> envie seu documento embaçado</li>
                      </ul>
                  </p>
                  )}
                  {typeDoc === 'DRIVER_LICENSE_VERSE' && (
                     <p>
                     <ul>
                       <li>Envie o verso da CNH com boa definição</li>
                       <li><strong>Não</strong> envie seu documento embaçado</li>
                     </ul>
                   </p>
                  )}

                  {typeDoc === 'IDENTITY_CARD_FRONT' && (
                    <p>
                    <ul>
                      <li>Envie o RG com boa definição</li>
                      <li><strong>Não</strong> envie seu documento embaçado</li>
                    </ul>
                  </p>
                  )}
                  {typeDoc === 'IDENTITY_CARD_VERSE' && (
                    <p>
                    <ul>
                      <li>Envie o verso do RG com boa definição</li>
                      <li><strong>Não</strong> envie seu documento embaçado</li>
                    </ul>
                  </p>
                  )}

                  {typeDoc === 'SELFIE' && (
                    <p>
                      <ul>
                        <li>Escolha uma foto com boa iluminação</li>
                        <li><strong>Não</strong> envie fotos com acessórios que escondem o seu rosto</li>
                        <li><strong>Não</strong> envie fotos com mais de uma pessoa</li>
                      </ul>
                    </p>
                  )}
                </div>
                </>

              )}
              {!!errorMessage && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5 mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {errorMessage}
                    </p>
                  </div>
                </div>
              </div>
              )}
            </WrapperForm>
            </>
          )}

          {step === 66 && (
            <Text>
              Infelizmente não foi possível abrir sua conta neste momento.
            </Text>
          )}

           {step === 99 && (
            <Text>
              Pronto! Agora é só esperar a análise dos dados. Em até 1 dia você irá receber a resposta em seu e-mail.
            </Text>
          )}
          </WrapperContent>
        </WrapperRight>
      </ContentWrapper>
    </Container>
  );
};

