export const professions = {
	"Administrador de empresas": "1",
	"Advogado(a)": "2",
	"Aeroviários, empregados de aeroportos e similares": "3",
	"Agente de viagens, guia e outros do setor": "4",
	"Agrônomo": "6",
	"Ajudante Geral, Auxiliar de Serviços Gerais": "115",
	"Ajudante de Pedreiro, Servente de Pedreiro": "115",
	"Aposentados em geral": "7",
	"Arquiteto(a)": "8",
	"Assistente social": "9",
	"Atendente de Enfermagem, Auxiliar de Enfermagem, Auxiliar de Creche": "102",
	"Atleta Profissional e Técnico(a) em Desportos": "91",
	"Atuario(a), Matemático(a)": "90",
	"Autônomo(a)": "11",
	"Auxiliar Laboratório, Sapateiro(a), Chaveiro(a), Relojoeiro(a), Jardineiro(a)": "52",
	"Auxiliar de Administrativo": "94",
	"Auxiliar de Cozinha, Auxiliar de Costura, Auxiliar de Limpeza, Auxiliar de Copa": "114",
	"Auxiliar de Escritório, Auxiliar de Almoxarifado, Conferente, Bilheteiro(a)": "108",
	"Bancário(a), Economiário(a)": "13",
	"Bibliotecário(a), Arquivista, Museólogo(a), Arqueólogo(a)": "15",
	"Biólogo(a), Biomédico(a)": "16",
	"Cabeleireiro(a), Barbeiro(a), Manicure, Pedicure, Maquilador(a), Massagista": "17",
	"Calceteiro(a), Canteiro(a), Manobrista, Vidraceiro(a)": "77",
	"Cargo de direção e assessoramento superior": "10",
	"Carpinteiro(a), Marceneiro(a)": "18",
	"Chefe Intermediário(a), Encarregado(a) de Serviços, Supervisor(a), Chefes de Seção": "106",
	"Ciclista": "116",
	"Comerciários(as) em geral": "19",
	"Contador(a)": "22",
	"Corretor(a) de Imóveis, Seguros, Títulos e Valores, Bens, Detetive Particular": "23",
	"Costureiro(a), Bordadeiro(a), Tapeceiro(a), Overloquista, Pespontadeiro(a)": "101",
	"Criação de pequenos animais, granjas e similares": "12",
	"Desenhista Comercial": "34",
	"Desenhista Técnico, Projetista e similares": "25",
	"Digitador(a)": "95",
	"Diretor(a), Orientador(a) Pedagógico, Secretario(a) de Escola e similares.": "27",
	"Dona de Casa, Prenda Domésticas": "104",
	"Economista": "26",
	"Eletricista de Manutenção de Veículos, Maquinas e Aparelhos": "28",
	"Em branco": "109",
	"Empregado(a) de Restaurante, Buffet, Lanchonete, Padaria e Refeições Industriais": "100",
	"Empregados(as) domésticos(as) em geral": "87",
	"Empresário(a)": "14",
	"Enfermeiro(a), Nutricionista": "30",
	"Engenheiro(a)": "31",
	"Escultor(a), Pintor(a), Artista Plástico e similares": "33",
	"Estagiário(a), Bolsista, Estudante, Trainees e similares": "35",
	"Estivador(a), Carregador(a), Embalador(a)": "65",
	"Farmacêutico(a)": "36",
	"Ferramenteiro(a)": "37",
	"Fiscal De Trafego e similares": "79",
	"Fisioterapeuta, Terapeuta Nutricional": "39",
	"Funcionário(a) de Empresas Publicas": "56",
	"Físico(a)": "38",
	"Gerente": "110",
	"Geógrafo(a)": "43",
	"Geólogo(a)": "85",
	"Jornaleiro(a), Florista": "50",
	"Jornalista": "51",
	"Locutor(a), Radialista, Comentarista de Radio e TV": "71",
	"Mecânico(a) de Manutenção de Veículos Automotores": "53",
	"Metalúrgico(a)": "55",
	"Ministro(a) de Tribunal Superior, Juiz(a), Desembargador(a)": "92",
	"Ministro(a), Governador(a), Prefeito(a), Secretário(a) de Estado ou Município": "64",
	"Modelo de Modas": "78",
	"Motociclista": "116",
	"Motorista Autônomo, Taxista": "57",
	"Motorista Empregado do Transporte de Passageiros": "113",
	"Musico(a)": "58",
	"Médico(a)": "54",
	"Não Classificado": "75",
	"Odontólogo(a)": "24",
	"Officeboy": "116",
	"Operador(a) de Máquinas de Produção em Geral": "105",
	"Ourives, Joalheiro(a)": "49",
	"Outros Técnicos(as) de Nivel Medio": "96",
	"Outros": "76",
	"Pedreiro(a), Encanador(a), Azulejista, Impermeabilizador(a), Colocador(a) (Tapete, Sinteco) e similares": "60",
	"Pensionista": "61",
	"Pintor(a) de Paredes, Gessista": "63",
	"Porteiro(a), Ascensorista, Garagista, Faxineiro(a)": "107",
	"Praça da PM (Soldado, Cabo, Sargento, Subtenente)": "97",
	"Professor(a) de Ensino 1º e 2º Graus": "67",
	"Profissionais de Letras e Artes": "32",
	"Promotor(a) de Vendas e Outros": "68",
	"Proprietário(a) de Estabelecimento Industrial": "47",
	"Proprietário(a) de Pequenas e Microempresas, Empreiteiro(a), Construtor(a)": "21",
	"Proprietário(a) de estabelecimento comercial": "20",
	"Psicólogo(a)": "66",
	"Publicidade (contato, diretor de arte, editor e similares)": "69",
	"Químico(a)": "70",
	"Representante Comercial, Caixeiro(a) Viajante": "74",
	"Sacerdote, Padre, Pastor e similares": "88",
	"Secretario(a), Estenografo(a), Datilografo(a), Recepcionista, Telefonista, Operador(a) de Telemarketing e similares": "93",
	"Serralheiro(a), Impressor, Linotipista, Bloquista, Operador(a) Offset, Operador(a) Rotativa": "44",
	"Servidor Publico Estadual": "40",
	"Servidor Publico Federal": "41",
	"Servidor Público Municipal": "42",
	"Sociólogo(a)": "89",
	"Trabalhador agrícola": "5",
	"Trabalhador da Pesca": "62",
	"Trabalhador(a) Contábil, Caixas e similares": "98",
	"Vendedor(a) Autônomo, Ambulante, Camelôs e similares": "111",
	"Vendedor(a) de Comércio Varejista e Atacadista": "112",
	"Veterinário(a), Zootécnico(a)": "84",
	"Vigia, Vigilante, Guarda, Segurança, Bombeiro(a)": "80",
	"Vive de Renda, Aluguel, Sócio, Proprietário": "103"
}