import styled from "styled-components";

// import striclaptopimage from "../../assets/striclaptopimage.png";
import SignInImage from "../../assets/signinimage.png";


interface CheckBoxProps {
  isActive: boolean;
}

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
`;

export const WrapperLeft = styled.div`
  background-color: var(--gray);
  /* min-height: 100vh; */
  background-image: url(${SignInImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: none;
  flex: 1;

  @media (min-width: 840px) {
    display: block;
  }
`;

export const WrapperRight = styled.div`
  min-height: 100vh;
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const GoBackButtonWrapper = styled.button`
  font-size: 14px;
  color: var(--gray);
  text-decoration: underline;
  background-color: transparent;
  border-style: none;
  text-align: start;

  > svg {
    margin-right: 12px;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 488px;
  margin: auto;
`;

export const Title = styled.span`
  font-size: 32px;
  color: var(--gray);
  font-weight: var(--light);
  max-width: 384px;
  line-height: 35px;
  margin-bottom: 21px;

  > strong {
    font-weight: var(--bold);
  }
`;

export const Text = styled.span`
  font-size: 16px;
  color: var(--gray-1-lp);
  font-family: var(--regular);
  max-width: 410px;
  line-height: 28px;
  margin-bottom: 21px;
`;

export const TextRequirement = styled.strong`
  text-transform: uppercase;
  color: var(--gray);
  font-size: 16px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: var(--bold);
  color: var(--white);
  position: relative;
  text-align: center;

  @media (min-width: 1120px) {
    text-align: start;
  }
`;

export const Line = styled.div`
  height: 3px;
  width: 15px;
  background: var(--cian);
  margin: 0px 0px 14px 0px;

  @media (min-width: 1120px) {
    flex-direction: row;
    align-items: center;
    margin: 0px 12px 0px 0px;
  }
`;

export const Requirement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 14px 0px;
`;

export const WrapperButton = styled.div`
  width: 100%;
  @media (min-width: 840px) {
    max-width: 176px;
  }
`;

export const WrapperForm = styled.form``;

export const WrapperSelect = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;
`;

export const Label = styled.span`
  font-size: 18px;
  font-weight: var(--bold);
  color: var(--gray-1-lp);
  margin-bottom: 18px;
`;

export const WrapperOptions = styled.div`
  display: flex;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;

  > label {
    font-size: 18px;
    font-weight: var(--bold);
    color: var(--gray-1-lp);
    margin-left: 15px;
  }

  & + div {
    margin-left: 78px;
  }
`;

export const CheckBox = styled.div<CheckBoxProps>`
  height: 24px;
  width: 24px;
  border: 1px solid var(--cian);
  border-radius: 4px;
  background-color: ${(props) =>
    props.isActive ? "var(--cian)" : "transparent"};
`;


export const Button = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cian);
  text-decoration: none;

  font-family: var(--multi-bold);
  color: var(--white);
  font-size: 16px;
  font-weight: var(--extraBold);

  > span {
    font-family: var(--multi-bold);
    color: var(--white);
    font-size: 16px;
    font-weight: var(--extraBold);
  }
`;

export const ResendButton = styled.button`
  /* width: 100%; */
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #343434;
  text-decoration: none;

  padding: 0 15px;
  margin-top: 25px;

  margin-bottom: 15px;

  font-family: var(--multi-bold);
  color: white;
  font-size: 16px;
  font-weight: var(--extraBold);

  > span {
    font-family: var(--multi-bold);
    color: var(--white);
    font-size: 16px;
    font-weight: var(--extraBold);
  }
`;
