import React, { useEffect, useState, Fragment, FormEvent, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/button";

import { Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, XCircleIcon, XIcon } from '@heroicons/react/outline'

import {
  Action,
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  RedirectWrapperButton,
  Search,
  Table,
  TableWrapper,
  ArrowRightDepositIcon,
  ButtonsTableWrapper,
} from "./styles";

import { Button as ButtonForm } from '../createAccountForm/styles'

import { service, serviceError } from "../../services/ajio";
import { documentFormatted } from "../../utils/documentFormatted";
import { currencyFormat } from "../../utils/currencyFormat";
import { FormInput } from "../../components/LPFormButton2";
import { Loading } from "../../components/loading";
import { Skeleton } from "../../components/skeleton";
import { Money } from "phosphor-react";
import { format } from "date-fns";
import TextInput from "../../components/TextInput";

interface Transaction {
  id: string
  transaction_id: string | null
  status: string
  direction: "out" | "in"
  amount: number;
  description: string
  type: string
}

interface Account {
  id: string
  name: string;
  balance: number;
  document: string
  email: string
}

export const Aprovacoes: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams()

  const [loading, setLoading] = useState(true);
  const [loadingExecute, setLoadingExecute] = useState(false);
  const [error, setError] = useState("");

  const [account, setAccount] = useState<Account>({} as Account);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const [idsAprove, setIdsAprove] = useState<string[]>([]);
  const [idsReprove, setIdsReprove] = useState<string[]>([]);

  const [pin, setPin] = useState("");




  async function handleApproveList() {
    setLoading(true);
    try {
      const { data } = await service.get(`process-payments?graphic_id=${id}`);
      const novasTransactions = data.GraphicAccountTransaction.filter((transaction: Transaction) => {
        return transaction.status === 'waiting'
      })

      setAccount({...data, GraphicAccountTransaction: undefined});
      setTransactions(novasTransactions)


    } catch (err) {
      setError(serviceError(err).friend);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleApproveList()
  } ,[]);


  async function handleAprovve(transaction_id: string) {
    let novasAprovacoes = idsAprove.filter(aprovacao => aprovacao !== transaction_id);
    let novasReprovacoes = idsReprove.filter(aprovacao => aprovacao !== transaction_id);
    setIdsReprove([...novasReprovacoes])
    setIdsAprove([...novasAprovacoes, transaction_id]);
  }

  async function handleReprove(transaction_id: string) {
    let novasAprovacoes = idsAprove.filter(aprovacao => aprovacao !== transaction_id);
    let novasReprovacoes = idsReprove.filter(aprovacao => aprovacao !== transaction_id);
    setIdsReprove([...novasReprovacoes, transaction_id]);
    setIdsAprove([...novasAprovacoes]);
  }

  async function handleApproveAll() {
    setError('')
    if (!pin || pin.length !== 4) {
      setError("Digite a senha de 4 dígitos.");
      return;
    }

    if (idsAprove.length <= 0 && idsReprove.length <= 0) {
      setError("É preciso selecionar ao menos um item");
      return;
    }

    for (let i = 0; i < idsAprove.length; i+=1) {
      setLoadingExecute(true);
      try {
        const current = idsAprove[i];

        await service.post("/process-payments", {
          pin,
          payment_id: current,
          approve: true,
        });

        await handleApproveList();
      } catch (err) {
        //
      }
    }

    for (let i = 0; i < idsReprove.length; i+=1) {
      setLoadingExecute(true);
      try {
        const current = idsReprove[i];

        await service.post("/process-payments", {
          pin,
          payment_id: current,
          approve: false,
        });

        await handleApproveList();
      } catch (err) {
        //
      }
    }

    setLoadingExecute(false);
    // await handleApproveList();
  }

  return (
    <>
      <Container>
        <Content>
          <ContentWrapper>
            <Action>
              <Search>
                <span>
                  Liste todas as suas transações pendentes da conta: <br/>
                  {account.name} - {documentFormatted(account.document)}
                </span>

              </Search>
              {/* <ButtonWrapper>
                <Button handleFunction={() => {}} title="Criar Conta" />
              </ButtonWrapper> */}
            </Action>
            <Table>
              <TableWrapper>
                {loading ? (
                  <Skeleton space={4} isActive={loading} repeat={5} />
                ) : (
                  <>
                    {transactions && transactions.length <= 0 ? (
                      <div className="text-center p-4">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            vectorEffect="non-scaling-stroke"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                          />
                        </svg>
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">Nenhuma transação para aprovar.</h3>
                      </div>
                    ) : (
                      <>
                        <table>
                          <tbody>
                            <tr>
                              <th>Descrição</th>
                              <th>Tipo</th>
                              <th>Valor</th>
                              <th>Saldo na conta</th>
                              <th>Aprovar</th>
                              <th>Reprovar</th>
                            </tr>

                            {transactions.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td>{item.description}</td>
                                  <td>{item.type}</td>
                                  <td>{currencyFormat(item.amount)}</td>
                                  <td>{currencyFormat(account.balance)}</td>

                                  <td>
                                    {idsAprove.includes(item.id) ? (
                                      <button onClick={() => handleAprovve(item.id)}
                                      type="button"
                                      className="inline-flex items-center gap-x-1.5 rounded-md bg-green-900 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >
                                        Selecionado
                                        <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                      </button>
                                    ): (
                                      <button onClick={() => handleAprovve(item.id)}
                                      type="button"
                                      className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >
                                      Aprovar
                                        {/* <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" /> */}
                                      </button>
                                    )}

                                  </td>
                                  <td>
                                    {idsReprove.includes(item.id) ? (
                                        <button onClick={() => handleReprove(item.id)}
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 rounded-md bg-red-900 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                        >
                                        Selecionado
                                          <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                        </button>
                                    ) : (
                                    <button onClick={() => handleReprove(item.id)}
                                      type="button"
                                      className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                    >
                                      Reprovar
                                      {/* <XCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" /> */}
                                    </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                  </>
                )}

             {(idsAprove.length > 0 || idsReprove.length > 0) && (
              <>
               <div className="p-4 flex justify-end">
                <div className="w-48 ">
                  <TextInput
                    placeholder="Senha de 4 dígitos"
                    maxLength={4}
                    value={pin}
                    type="password"
                    onChange={input => setPin(input.target.value)}
                    />
                        </div>
                </div>


                <div className="mb-4 pr-4 flex justify-end">
                  <button onClick={handleApproveAll}
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  Aprovar/reprovar itens selecionados
                  </button>
                </div>

                <div className="mb-4 pr-4 flex justify-end">
                  {error && (
                    <div>{error}</div>
                  )}
                </div>
              </>
             )}
              </TableWrapper>
            </Table>
          </ContentWrapper>
        </Content>
      </Container>



    </>
  );
};
