import React, { useEffect, useState, Fragment, FormEvent, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";

import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

import {
  Action,
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  RedirectWrapperButton,
  Search,
  Table,
  TableWrapper,
  ArrowRightDepositIcon,
  ButtonsTableWrapper,
} from "./styles";

import { Button as ButtonForm } from '../createAccountForm/styles'

import { service, serviceError } from "../../services/ajio";
import { documentFormatted } from "../../utils/documentFormatted";
import { currencyFormat } from "../../utils/currencyFormat";
import { FormInput } from "../../components/LPFormButton2";
import { Loading } from "../../components/loading";
import { Skeleton } from "../../components/skeleton";
import { Money } from "phosphor-react";
import { format } from "date-fns";
import { de } from "date-fns/locale";

interface Account {
  id: string;
  balance: number;
  name: string;
  status: string;
  email: string;
  document: string;
}

interface ExtractType {
  id: string;
  description: string;
  amount: number;
  created_at: string;
  direction: "in" | "out"
  status: string;
}


export const GerenciarContas: React.FC = () => {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState<Account[]>([]);

  const [currentItem, setCurrentItem] = useState<Account>({} as Account);

  const [name, setName] = useState("");
  const [document, setDocument] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingCreateAccount, setLoadingCreateAccount] = useState(false);
  const [loadingOperation, setLoadingOperation] = useState(false);

  const [openModalExtract, setOpenModalExtract] = useState(false);
  const [extract, setExtract] = useState<ExtractType[]>([]);
  const [loadExtract, setLoadExtract] = useState(false);

  const [balanceTotal, setBalanceTotal] = useState(0);

  const [maskDocument, setMaskDocument] = useState('99.999.999/9999-99')

  const [open, setOpen] = useState(false)
  const [openBalance, setOpenBalance] = useState(false)

  const [operation, setOperation] = useState('');

  const [amount, setAmount] = useState('')


  const [error, setError] = useState('');

  const cancelButtonRef = useRef(null);

  function handleDocument(doc: string) {
    setDocument(doc)
    if (doc.length >= 15) {
      setMaskDocument("99.999.999/9999-99")
    } else {
      setMaskDocument("999.999.999-999")
    }
  }

  async function fetchAccounts() {
    try {
      setLoading(true);
      const { data } = await service.get("/graphic");
      setAccounts(data.accounts);

      const total = data.accounts.reduce((acc: number, item: Account) => {
        return acc += item.balance
      }, 0)
      setBalanceTotal(total);
    } catch (err) {
      // err
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAccounts();
  }, []);


  async function handleOperation() {
    setLoadingOperation(true);
    const parseAmount = Number(amount.replace(/[/,R$ .,-\s]/g, "")) / 100;
    try {
      await service.post(`graphic/${operation}`, {
        graphic_account_id: currentItem.id,
        amount: parseAmount,
      })
      await fetchAccounts()
      setOpenBalance(false);
    } catch (err) {
      setError(serviceError(err).friend)
    } finally {
      setLoadingOperation(false);
    }
  }


  async function handleCreateAccount(e: FormEvent) {
    e.preventDefault();
    setLoadingCreateAccount(true);
    try {
      const raw = {
        name,
        document: document.replace(/[/,.,-\s]/g, ""),
        email,
        password,
      };

      await service.post("/graphic/create", raw);
      await fetchAccounts();
      setOpen(false);
    } catch (err) {
      setError(serviceError(err).friend);
    } finally {
      setLoadingCreateAccount(false);
    }
  }


  const handleNextScreen = () => {
    // navigate('/paybills/subscribe')
    setOpen(true);
  }

  function handleBalance(item: Account) {
    setCurrentItem(item);
    setOpenBalance(true);
  }


  async function handleExtract(item: Account) {
    setLoadExtract(true);
    setOpenModalExtract(true);
    try {
      const { data } = await service.get(`graphic/transactions?graphic_account_id=${item.id}`);
      setExtract(data.transactions)

    } catch (err) {
      setError(serviceError(err).friend);
    } finally {
      setLoadExtract(true);
    }
  }

  function handleApprove(item: Account) {
    navigate(`/aprovacoes/${item.id}`);
  }

  function getDescription(description: string) {
    if (description) {
      if (description === "waiting") {
        return "Aguar. Aprovação"
      }

      if (description === "done") {
        return "Concluído"
      }
    }

    return description
  }


  return (
    <>
      <Container>
        <Content>
          <ContentWrapper>
            <Action>
              <Search>
                <span>
                  Liste todas as suas contas, crie conta e realize depositos e/ou saques. Você também
                  pode acessar o extrado delas clicando em detalhes.
                </span>
                {/* <InputWrapper>
                  <input
                    type="text"
                    placeholder="Pesquisar por CPF/CNPJ"
                  />
                </InputWrapper> */}
              </Search>
              <ButtonWrapper>
                <Button handleFunction={handleNextScreen} title="Criar Conta" />
              </ButtonWrapper>
            </Action>
            <Table>
              <TableWrapper>
                {loading ? (
                  <Skeleton space={4} isActive={loading} repeat={5} />
                ) : (
                  <>
                    {accounts && accounts.length <= 0 ? (
                      <div className="text-center p-4">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            vectorEffect="non-scaling-stroke"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                          />
                        </svg>
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">Nenhuma conta</h3>
                        <p className="mt-1 text-sm text-gray-500">Você ainda não criou nenhuma conta adicional.</p>
                      </div>
                    ) : (
                      <>
                        <div className="p-4 flex justify-end">Saldo em contas: {currencyFormat(balanceTotal)}</div>
                        <table>
                          <tbody>
                            <tr>
                              <th>Nome</th>
                              <th>CPF/CNPJ</th>
                              <th>Saldo</th>
                              <th>Gerenciar</th>
                              <th>Extrato</th>
                              <th>Aprovações</th>
                            </tr>

                            {accounts.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td>{item.name}</td>
                                  <td>{documentFormatted(item.document)}</td>
                                  <td>{currencyFormat(item.balance)}</td>
                                  <td>
                                    <ButtonsTableWrapper>
                                      <RedirectWrapperButton onClick={() => handleBalance(item)}>
                                        <span>Gerenciar Saldo</span>
                                        <ArrowRightDepositIcon />
                                      </RedirectWrapperButton>
                                    </ButtonsTableWrapper>
                                  </td>

                                  <td>
                                    <ButtonsTableWrapper>
                                      <RedirectWrapperButton onClick={() => handleExtract(item)}>
                                        <span>Extrato</span>
                                        <ArrowRightDepositIcon />
                                      </RedirectWrapperButton>
                                    </ButtonsTableWrapper>
                                  </td>
                                  <td>
                                    <ButtonsTableWrapper>
                                      <RedirectWrapperButton onClick={() => handleApprove(item)}>
                                        <span>Ir para aprovações</span>
                                        <ArrowRightDepositIcon />
                                      </RedirectWrapperButton>
                                    </ButtonsTableWrapper>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                  </>
                )}
              </TableWrapper>
            </Table>
          </ContentWrapper>
        </Content>
      </Container>


      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      onSubmit={handleCreateAccount}
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                Nova Conta
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                {/* Get started by filling in the information below to create your new project. */}
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="px-4 py-6 sm:px-6">
                          <FormInput
                            label="NOME"
                            required
                            value={name}
                            // error={error.name || ""}
                            onChanged={(e) => setName(e.target.value)}
                          />

                          <FormInput
                            label="CPF/CNPJ"
                            required
                            mask={maskDocument}
                            value={document}
                            // error={error.name || ""}
                            onChanged={(e) => handleDocument(e.target.value)}
                          />

                          <FormInput
                            label="E-mail"
                            required
                            value={email}
                            // error={error.name || ""}
                            onChanged={(e) => setEmail(e.target.value)}
                          />

                          <FormInput
                            label="Senha"
                            required
                            type="password"
                            value={password}
                            // error={error.name || ""}
                            onChanged={(e) => setPassword(e.target.value)}
                          />

                          {/* <WrapperButton> */}
                          <ButtonForm type="submit">
                            <Loading isLoading={loadingCreateAccount} />
                            Criar Conta
                          </ButtonForm>

                          <div className="mt-2 text-red-500">
                            {error}
                          </div>
                          {/* </WrapperButton> */}
                        </div>


                      </div>

                      {/* Action buttons */}
                      {/* <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Criar Conta
                        </button>
                      </div>
                    </div> */}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>



      <Transition.Root show={openBalance} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenBalance}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <Money className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Gerenciar Saldo
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-md text-gray-500">
                          Escolha a opção que deseja abaixo
                        </p>
                        <div className="mt-5 flex flex-col justify-center gap-4">
                          <div className="flex flex-1 gap-4">
                            <ButtonForm
                              type="button"
                              onClick={() => setOperation('deposit')}
                              style={{ border: operation === 'deposit' ? '2px solid #333' : '2px solid var(--cian)' }}
                            >
                              Depositar
                            </ButtonForm>

                            <ButtonForm
                              type="button"
                              style={{
                                backgroundColor: '#333',
                                border: operation === 'withdraw' ? '2px solid var(--cian)' : '2px solid #333'
                              }}
                              onClick={() => setOperation('withdraw')}>
                              Retirar
                            </ButtonForm>
                          </div>

                          {!!operation && (
                            <>
                              <div className="w-full flex flex-1">
                                <FormInput
                                  label="Valor"
                                  mask="currency"
                                  required
                                  onChanged={(e) => setAmount(e.target.value)}
                                />
                              </div>

                              <div>
                                <ButtonForm type="button" onClick={handleOperation}>
                                  <Loading isLoading={loadingOperation} />
                                  Realizar operação
                                </ButtonForm>
                                <div className="mt-2 text-red-500">
                                  {error}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex justify-center">
                    {/* <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={() => {}}
                  >
                    Continuar
                  </button> */}
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => setOpenBalance(false)}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>


      <Transition.Root show={openModalExtract} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenModalExtract}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      onSubmit={handleCreateAccount}
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                Extrato
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                {/* Get started by filling in the information below to create your new project. */}
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpenModalExtract(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="px-4 py-6 sm:px-6">
                          <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                {extract && extract.length <= 0 ? (
                                  <div>Não há itens para exibir.</div>
                                ) : (
                                  <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                      <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                          Data
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          Descrição
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          Valor
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                          Status
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                      {extract.map((item) => (
                                        <tr key={item.id}>
                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                            {format(new Date(item.created_at), 'dd/MM/yyyy')}
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.description}</td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {item.direction === 'out' ? '-' : ''}
                                            {currencyFormat(item.amount)}
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getDescription(item.status)}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>


    </>
  );
};
