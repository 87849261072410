import { DuplicateIcon } from "@heroicons/react/solid";
import React, { Fragment, useRef, useEffect, useState } from "react";
import { Skeleton } from "../../components/skeleton";
import { api } from "../../services/api";
import { dateFormat } from "../../utils/dateFormat";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'

import {
  ActionPixTable,
  // KeyCpnjIcon,
  // KeyPixTable,
  // ShareIcon,
  TrashIcon,
} from "../extract/styles";
import { Container, Content, Table, TableWrapper } from "./styles";
import { useNotification } from "../../hooks/notification";
import { Button } from "../../components/button";
import { useNavigate } from "react-router-dom";
import { service } from "../../services/ajio";

interface KeysProps {
  key: string;
  keyType: string;
  keyStatus: string;
  dateKeyCreated: string;
}

export const PixMyKeys = () => {
  const [loading, setLoading] = useState(true);
  const [keys, setKeys] = useState<KeysProps[]>([]);

  const navigate = useNavigate();
  const { setOpenNotification } = useNotification();

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [key, setKey] = useState<KeysProps>({} as KeysProps);

  async function getKeys() {
    service.get('/keys').then(({data}) => {
      setKeys(data.keys);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    })
  }

  useEffect(() => {
    getKeys();
  }, []);

  function parseType(type: string): string {
    if (type === 'evp') {
      return 'Aleatória';
    }

    if (type === 'email') {
      return 'E-mail';
    }

    if (type === 'nationalRegistration') {
      return 'CPF/CNPJ';
    }

    if (type === 'celular') {
      return 'Celular';
    }

    return type;
  }

  function parseStatus(status: string): string {
    if( status === 'WAITING') {
      return 'Aguardando';
    }

    if( status === 'CREATED') {
      return 'Aguardando';
    }

    if (status === 'PROCESSED') {
      return 'Em processamento';
    }

    if (status === 'REGISTERED') {
      return 'Registrada'
    }

    if (status === 'PRE-CANCELED') {
      return 'Em cancelamento';
    }

    if (status === 'CANCELED') {
      return 'Cancelada'
    }

    if (status === 'ERRORPORTABILITY') {
      return 'Erro de portabilidade'
    }

    return status;
  }

  function handleCancel(key: KeysProps) {
    setKey(key);
    setOpen(true);
  }

  async function cancelPix() {
    try {
      setOpen(false);
      await service.delete(`/pix/keys/delete/${key.key}`);
      setOpenNotification({
        type: 'success',
        title: 'Chave cancelada',
        notification: 'A chave Pix foi cancelada com sucesso',
      });
      getKeys();
    } catch (err) {
      setOpenNotification({
        type: 'error',
        title: 'Chave não cancelada',
        notification: 'Não foi possível cancelar a chave Pix',
      });
    }

  }

  return (
    <Container>
      <Content>
        <Table>
          <div className="md:hidden flex justify-end">
            <div className="mb-5">
            <Button title="Cadastrar nova chave" handleFunction={() => {
                navigate("/pix/mykeys/new")
              }} />
            </div>
          </div>

          <TableWrapper>
          <Skeleton space={4} isActive={loading} repeat={3} />

          {!loading && keys.length <= 0 && (
            <div className="p-6">
              <div className="text-center">
                <h3 className="mt-2 text-sm font-medium text-gray-900">Nenhuma chave Pix</h3>
                <p className="mt-1 text-sm text-gray-500">Cadastre uma chave Pix e comece a usar todos os benefícios do Pix</p>
              </div>
            </div>
          )}


            <table>
             {!loading && keys.length > 0 && (
              <thead>
               <tr>
                  <th>Data</th>
                  <th>Chave</th>
                  <th>Tipo de chave</th>
                  <th>Status</th>
                  {/* <th>Ação</th> */}
                </tr>
              </thead>
             )}

             <tbody>

              {keys.map(key => (
                <tr key={key.key}>
                  <td>{dateFormat(key.dateKeyCreated)}</td>
                  <td>{key.key}</td>
                  <td>{parseType(key.keyType)}</td>
                  {/* <td>{parseStatus(key.status)}</td> */}
                  <td>
                      <ActionPixTable>
                      <CopyToClipboard text={key.key}
                        onCopy={() =>
                          setOpenNotification({
                            type: 'success',
                            title: 'Chave copiada',
                            notification: 'Chave copiada com sucesso.'
                          })
                        }>
                          <DuplicateIcon width={21} color="var(--cian)"  className="cursor-pointer"/>
                      </CopyToClipboard>

                      {/* <TrashIcon onClick={() => handleCancel(key)} /> */}

                      </ActionPixTable>
                    </td>
                </tr>
              ))}

              </tbody>


                  {/* <tr>
                    <td>27/02/2022</td>
                    <td>31.544.975/0001-13</td>
                    <td>
                      <KeyPixTable>
                        <KeyCpnjIcon />
                        <span>CPNJ</span>
                      </KeyPixTable>
                    </td>
                    <td>
                      <ActionPixTable>
                        <ShareIcon />
                        <TrashIcon />
                      </ActionPixTable>
                    </td>
                  </tr> */}

                  {/* <tr>
                    <td>24/02/2022</td>
                    <td>comercial@moretti.com</td>
                    <td>
                      <KeyPixTable>
                        <MailIcon />
                        <span>E-mail</span>
                      </KeyPixTable>
                    </td>
                    <td>
                      <ActionPixTable>
                        <ShareIcon />
                        <TrashIcon />
                      </ActionPixTable>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td>21/02/2022</td>
                    <td>87498cre-654681-548468-bb3hy58hi</td>
                    <td>
                      <KeyPixTable>
                        <LockIcon />
                        <span>Chave Aleatória</span>
                      </KeyPixTable>
                    </td>
                    <td>
                      <ActionPixTable>
                        <ShareIcon />
                        <TrashIcon />
                      </ActionPixTable>
                    </td>
                  </tr> */}

            </table>
          </TableWrapper>
        </Table>
      </Content>
      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Cancelar chave PIX
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Tem certeza que deseja cancelas a chave <br/> <strong>{key.key}</strong>?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => cancelPix()}
                  >
                    Sim, cancelar chave Pix
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancelar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </Container>
  );
};
