import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useClient } from "../../hooks/client";
import { currencyFormat } from "../../utils/currencyFormat";
import { OptionSidebar } from "../optionSidebar";
// import { OptionSidebarButton } from "../optionSidebarButton";
import { Service } from "../service";
import {
    Container,
    WrapperHeader,
    InfoAccount,
    SideFix,
    SidebarIcon,
    BalanceInfoAccount,
    VisionIcon,
    // StricLogo,
    Arrowicon,
    BankMoneyIcon,
    HomeIcon,
    ReceiptIcon,
    TransferMoneyIcon,
    BillIcon,
    PixIcon,
    ChargeIcon,
    DepositIcon,
    OptionsWrapper,
    AccountDeclarationIcon,
    // ShareIcon
} from "./styles";
import { ExternalLinkIcon } from "@heroicons/react/outline";

type props = {
    isActive: boolean;
    handleOpenReferenceModal: () => void
}

export const Sidebar: React.FC<props> = ({isActive, handleOpenReferenceModal}) => {
    const route = useLocation();
    const { account, user } = useAuth();
    const { client } = useClient();

    const [bankServicesIsOpen, setBankServicesIsOpen] = useState(true);
    const [myAccountIsOpen, setMyAccountIsOpen] = useState(true);
    const [isBalanceVisible, setIsBalanceVisible] = useState(() => {
      const visible = localStorage.getItem('visible');

      if (visible && visible === "true") {
        return true;
      } else {
        return false;
      }
    });

    const handleBankServiceToggle = () => {
        setBankServicesIsOpen(state => !state)
    }

    const handleMyAccountoggle = () => {
        setMyAccountIsOpen(state => !state)
    }

    const handleBalanceAccounttoggle = () => {
        localStorage.setItem('visible', (!isBalanceVisible).toString());
        setIsBalanceVisible(state => !state)
    }

    return (
        <Container isActive={isActive}>
            <WrapperHeader>
                <SidebarIcon/>
                <img src={client.whiteLogo.image} alt={client.name} style={{
                  width: client.whiteLogo.width,
                  height: client.whiteLogo.height,
                  marginLeft: '24px',
                 }} />
                {/* <StricLogo/> */}
            </WrapperHeader>
            <InfoAccount>
                <p>Saldo na conta</p>
                <BalanceInfoAccount>
                    <p>{ isBalanceVisible ? currencyFormat(account.balance) : 'R$ ***'}</p>
                    <button onClick={handleBalanceAccounttoggle}>
                        <VisionIcon/>
                    </button>
                </BalanceInfoAccount>
            </InfoAccount>
            <SideFix>

                <OptionSidebar
                isSelected={route.pathname === '/'}
                linkTo={'/'}
                title={'Inicio'}
                Icon={HomeIcon} />

                <OptionSidebar
                isSelected={route.pathname === '/extract'}     linkTo={'/extract'}
                title={'Extrato'}
                Icon={ReceiptIcon} />

                {/* <OptionSidebar
                isSelected={route.pathname === '/extract'}     linkTo={'/extract'}
                title={'Comprovantes'}
                Icon={BankMoneyIcon} /> */}

            </SideFix>
                <Service handleOption={handleBankServiceToggle} isOpen={bankServicesIsOpen} Icon={Arrowicon} title="Serviços Bancários"/>
                    <AnimatePresence>
                        {
                        bankServicesIsOpen &&
                        <OptionsWrapper>

                            {/* <OptionSidebar
                            isSelected={route.pathname.startsWith('/deposit')}
                            linkTo={'/deposit'}
                            title={'Depositar'}
                            Icon={DepositIcon} /> */}

                            {/* <OptionSidebar
                            isSelected={route.pathname === '/charge'}
                            linkTo={'/charge'}
                            title={'Cobrar'}
                            Icon={ChargeIcon} /> */}

                            <OptionSidebar
                            isSelected={route.pathname.startsWith('/pix')}
                            linkTo={'/pix'}
                            title={'Pix'}
                            Icon={PixIcon} />

                            <OptionSidebar
                            isSelected={route.pathname.startsWith('/paybills')}
                            linkTo={'/paybills'}
                            title={'Pagar Contas'}
                            Icon={BillIcon} />

                            <OptionSidebar
                            isSelected={route.pathname.includes('/subscribe')}
                            // linkTo={'/transfer'}
                            linkTo={'/transfer/subscribe'}
                            title={'Transferir TED/DOC'}
                            Icon={TransferMoneyIcon} />

                          {user.type !== 'GRAPHIC' && (
                            <>
                            <OptionSidebar
                            isSelected={route.pathname.includes('/list')}
                            // linkTo={'/transfer'}
                            linkTo={'/transfer/list'}
                            title={'Transferir Entr. Contas'}
                            Icon={BankMoneyIcon} />
                            </>
                          )}



                        </OptionsWrapper>
                        }
                    </AnimatePresence>
                    {user.type !== "GRAPHIC" && (
                      <>
                      <Service handleOption={handleMyAccountoggle} isOpen={myAccountIsOpen} Icon={Arrowicon} title="Gerenciar Contas"/>
                      <AnimatePresence>
                          {
                              myAccountIsOpen &&
                              <OptionsWrapper>

                              <OptionSidebar
                            isSelected={route.pathname.includes('/gerenciar')}
                              linkTo={'/gerenciar'}
                              title={'Gerenciar'}
                              Icon={AccountDeclarationIcon} />
                          </OptionsWrapper>
                          }
                      </AnimatePresence>
                      </>
                    )}

                <Service handleOption={handleMyAccountoggle} isOpen={myAccountIsOpen} Icon={Arrowicon} title="Minha Conta"/>
                    <AnimatePresence>
                        {
                            myAccountIsOpen &&
                            <OptionsWrapper>

                            {/* <OptionSidebar
                            isSelected={route.pathname === '/tax'}
                            linkTo={'/tax'}
                            title={'Tarifas'}
                            Icon={ChargeIcon} />



                            <OptionSidebar
                            isSelected={route.pathname === '/1'}
                            linkTo={'/1'}
                            title={'Ajuda'}
                            Icon={BillIcon} /> */}

                            {/* <OptionSidebarButton
                            handleFuction={handleOpenReferenceModal}
                            title={'Convide amigos'}
                            Icon={ShareIcon} /> */}



                            {/* {client.host === 'novo.nobebank.com.br' && (
                              <OptionSidebar
                                isSelected={route.pathname === '/signout'}
                                linkTo={'#'}
                                title={'Cobranças anteriores'}
                                onClick={() => {
                                  window.location.href = 'https://internet-banking-wb.vercel.app';
                                }}
                                Icon={TransferMoneyIcon} />
                            )} */}

                          {/* <OptionSidebar
                            isSelected={route.pathname === '/type-create-account'}
                            linkTo={'/type-create-account'}
                            title={'Adicionar Conta'}
                            Icon={ChargeIcon} /> */}

                            <OptionSidebar
                            isSelected={route.pathname === '/signout'}
                            linkTo={'/signout'}
                            title={'Sair'}
                            Icon={TransferMoneyIcon} />

                        </OptionsWrapper>
                        }
                    </AnimatePresence>
        </Container>
    )
}

