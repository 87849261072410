import { format } from "date-fns";
import React, { Fragment, FormEvent, useState } from "react";
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, } from '@heroicons/react/outline'

import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import { StepHeader } from "../../components/stepHeader";
import TextInput from "../../components/TextInput";
import { service, serviceError } from "../../services/ajio";
import { currencyFormat } from "../../utils/currencyFormat";
import { dateFormat } from "../../utils/dateFormat";
import { BoxText } from "../payBillsPayInvoice/styles";

import {
  BoxInfoWrapper,
  ButtonsWrapper,
  Container,
  ExpiresDate,
  InvoiceCode,
  Label,
  PaymentsDate,
  Title,
  ValidityWrapper,
  WrapperHeader,
} from "./styles";
import { useAuth } from "../../hooks/auth";

interface StateProps {
  type: string
  isValid: boolean
  wasPaid: boolean
  hasEnoughBalance: boolean
  isPayable: boolean
  isOverdue: boolean
  isInTime: boolean
  barcode: string
  checkedAt: string
  minTime: string
  maxTime: string
  dueDate: string
  limitDate: string
  nextValidDate: string
  amount: number
  discount: number
  fine: number
  interest: number
  recipientName: string
  recipientDocument: any
  payerName: any
  payerDocument: any
  amountRule: string
  amountRuleDescription: string
  hasMinimum: boolean
  minimumAmount: number
  hasMaximum: boolean
  maximumAmount: number
}

interface PaymentProps {
  id: string;
}

export const PayBillsInfoInvoice: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { account, refresh } = useAuth();

  const state = location.state as StateProps;

  const [value, setValue] = useState(state.amount);
  const [maskedValue, setMaskedValue] = useState('');
  const [dueAt, setDueAt] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [pinOpen, setPinOpen] = useState(false);
  const [pin, setPin] = useState('');
  const [hasPin, setHasPin] = useState(false);
  const [open, setOpen] = useState(false);
  const [paymentData, setPaymentData] = useState<PaymentProps>({} as PaymentProps);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    navigate("/paybills");
  };

  const handleConfirmButton = async () => {
    setLoading(true);
    setError('');
    try {
      const { data } = await service.post("/payments", {
        barCode: state.barcode,
        amount: value,
        pin,
      });

      setPaymentData(data.payment);
      setLoading(false);
      setOpen(true);
      refresh();
    } catch (err) {
        setLoading(false);
        const error = serviceError(err);
        setError(error.friend);
    }
    // navigate("/paybills/confirm-invoice");
  };

  const handleChange = (event: FormEvent, value: number, maskedValue: string) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  function onCloseModal() {
    setOpen(false);
    navigate("/extract");
  }

  return (
    <>
    <Container>
      <WrapperHeader>
      <StepHeader
        handleGoBackFunction={handleGoBack}
        title="Realizar Pagamento de Boleto "
      />
      </WrapperHeader>
      <BoxInfoWrapper>
        <Title>Código de Barras</Title>
        <InvoiceCode>{state.barcode}</InvoiceCode>
        <ValidityWrapper>
          <ExpiresDate>
            <Label>Vencimento</Label>
            <TextInput borderColor="#E3E3E3" placeholder="02/03/2022" type="text" value={dateFormat(state.dueDate)} disabled />
          </ExpiresDate>
          <PaymentsDate>
            <Label>Pagamento</Label>
            <TextInput borderColor="#E3E3E3" placeholder="02/03/2022" type="text" value={dueAt} disabled />
          </PaymentsDate>
        </ValidityWrapper>
        <ExpiresDate>
            <Label>Valor</Label>
            <TextInput borderColor="#E3E3E3" placeholder="02/03/2022" type="text" value={currencyFormat(state.amount)} disabled />
          </ExpiresDate>
          <ExpiresDate>
            <Label>Beneficiário</Label>
            <TextInput borderColor="#E3E3E3" placeholder="02/03/2022" type="text" value={state.recipientName} disabled />
          </ExpiresDate>
      </BoxInfoWrapper>
      <ButtonsWrapper>
        <Button handleFunction={handleCancel} color="#e3e3e3" title="Cancelar" />

        {!hasPin ? (
          <Button type="button" isLoading={loading} title="Continuar para senha" handleFunction={() => setPinOpen(true)} />
        ) : (
          <Button handleFunction={handleConfirmButton} title="Realizar pagamento" isLoading={loading} />
        )}



      </ButtonsWrapper>
      <BoxText>
              {error && (
                <div className="block mt-3 text-red-500">
                  {error}
              </div>
              )}
            </BoxText>
    </Container>

    <Transition.Root show={pinOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setPinOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Senha
                    </Dialog.Title>
                    <div className="mt-2">
                    <p className="mt-5 flex justify-center">
                        <div className="w-48 ">
                          <TextInput
                            placeholder="Senha de 4 dígitos"
                            maxLength={4}
                            value={pin}
                            type="password"
                            onChange={input => setPin(input.target.value)}
                            />
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                 <Button type="submit" isLoading={loading} title="Realizar Pagamento" handleFunction={() => {
                  setHasPin(true);
                  setPinOpen(false);
                  handleConfirmButton();
                 }} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>


    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Pagamento realizado com sucesso
                      <br />

                      {maskedValue}
                    </Dialog.Title>
                    <div className="mt-2 text-left">
                      <p className="text-sm text-gray-500">
                        <h2 className="mb-5 mt-5 text-lg">Dados da conta debitada</h2>
                         {/* <div>
                          <h2>Nome</h2>
                            <h3>{account.name}</h3>
                         </div> */}

                         {/* <div className="mt-5"> */}
                          {/* <h2>Documento</h2>
                            <h3>{documentFormatted(account.document)}</h3>
                         </div> */}

                         <div className="mt-5 flex">
                          <div><h2>Agência</h2>
                          <h3>1111</h3></div>

                          <div className="ml-28"><h2>Conta</h2>
                          <h3>{account.account_number}-{account.account_digit}</h3></div>
                         </div>

                         <h2 className="mb-5 mt-5 text-lg">Dados do favorecido</h2>

                         <div>
                          <h2>Nome</h2>
                            <h3>{state.recipientName}</h3>
                         </div>

                         <div className="mt-5">
                          <h2>Documento</h2>
                            <h3>{state.recipientName}</h3>
                         </div>

                         <div className="mt-5">
                          <h2>Código de Barras</h2>
                            <h3>{state.barcode}</h3>
                         </div>

                         <div className="mt-5">
                          <h2>ID da transação</h2>
                            <h3>{paymentData.id}</h3>
                         </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    onClick={() => onCloseModal()}
                  >
                    Ir para o extrato
                  </button>

                  <button
                    type="button"
                    className="mt-5 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2text-base font-medium  sm:text-sm text-black"
                    // onClick={() => onCloseModal()}
                  >
                    Comprovante disponível no extrato
                  </button>

                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

    </>
  );
};
