export interface ExtractItem {
  id: string;
  direction: "in" | "out"
  description: string;
  amount: number;
  created_at: string;
  operation: string;
}

export function extractDescription(item: ExtractItem): string {
  switch (item.operation) {
    case 'pix.sent': {
      if (item.direction === 'out') {
        return `Pix enviado para ${item.description}`;
      } else {
        return `Pix recebido de ${item.description}`
      }
    }

    case 'payment.sent': {
      if (item.direction === 'out') {
        return `Pagamento de contas para ${item.description}`;
      } else {
        return `Pagamento recebido de ${item.description}`
      }
    }

    case 'transfer.sent': {
      if (item.direction === 'out') {
        return `Transferência para ${item.description}`;
      } else {
        return `Transferência recebida de ${item.description}`
      }
    }

    case 'transfer.received': {
      if (item.direction === 'out') {
        return `Transferência para ${item.description}`;
      } else {
        return `Transferência recebida de ${item.description}`
      }
    }

    case 'p2p.sent': {
      if (item.direction === 'out') {
        return `Transferência para ${item.description}`;
      } else {
        return `Transferência recebida de ${item.description}`
      }
    }

    case 'p2p.received': {
      if (item.direction === 'out') {
        return `Transferência para ${item.description}`;
      } else {
        return `Transferência recebida de ${item.description}`
      }
    }

    default:
      return item.description;
  }
}
