import React from "react";
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Charge } from "../pages/charge";
// import { Confirm } from "../pages/confirm";
import { Dashboard } from "../pages/dashboard";
import { Deposit } from "../pages/deposit";
// import { Extract } from "../pages/extract";
import { Layout } from "../pages/layout";
import { PayBills } from "../pages/payBills";
import { PayBillsPayInvoice } from "../pages/payBillsPayInvoice";
import { PixMyKeys } from "../pages/pixMyKeys";
import { PixNewKey } from "../pages/pixNewKey";
import { PixOptions } from "../pages/pixOptions";
import { Receipt } from "../pages/receipt";
import { TransferSubscribe } from "../pages/transferSubscribe";
import { Tax } from "../pages/tax";
// import { TransferData } from "../pages/transferData";
import { Transfer } from "../pages/transfer";
import { TransferList } from "../pages/transferList";
import { PixList } from "../pages/pixList";
import { PixTransfer } from "../pages/pixTransfer";
import { PayBillsSchedulePayments } from "../pages/payBillsSchedulePayments";
import { PayBillsSubscribe } from "../pages/payBillsSubscribe";
import { PayBillsInfoInvoice } from "../pages/payBillsPayInvoiceInfo";
import { PayBillsInvoiceConfirm } from "../pages/payBillsInvoiceConfirm";
import { TransferConfirm } from "../pages/transferConfirm";
import { PixConfirm } from "../pages/pixConfirm";
import { SignIn } from "../pages/signIn";
import { FillOutToken } from "../pages/fillOutToken";
import { DepositInfoTedDoc } from "../pages/depositInfoTedDoc";
import { DepositNewInvoice } from "../pages/depositNewInvoice";
import { DepositNewInvoiceConfirm } from "../pages/depositNewInvoiceConfirm";
import { DepositInvoicesList } from "../pages/depositInvoicesList";
import { DepositPixMyKeys } from "../pages/depositPixMyKeys";
import Home from "../pages/Home";
import CreateAccountType from "../pages/createAccountType";
import Requirements from "../pages/createAccountRequirements";
import Form from "../pages/createAccountForm";

import { SignoutPage } from "../pages/signout";
import { useAuth } from "../hooks/auth";
import { Forgot } from "../pages/forgot";
import { MyAccount } from "../pages/myAccount";
import { PixTransferCopiaECola } from "../pages/pixTransfer/PixTransferCopiaECola";
import { PixConfirmCopieEcola } from "../pages/pixConfirm/PixConfirmCopieEcola";
import { SignInToken } from "../pages/SingnInToken";
import { GerenciarContas } from "../pages/gerenciarContas";
import { Aprovacoes } from "../pages/aprovacoes";

function RequireAuth({ children }: { children: JSX.Element }) {
  let { account } = useAuth();
  let location = useLocation();

  if (!account) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
}


const routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="home" element={<Home />} />
        <Route path="type-create-account" element={<CreateAccountType />} />
        <Route path="requirements-create-account" element={<Requirements />} />
        <Route path="create-account" element={<Form />} />

        <Route path="signin" element={<SignIn />} />
        <Route path="token" element={<FillOutToken />} />
        <Route path="forgot" element={< Forgot/>} />

        <Route path="signin-token/:token" element={<SignInToken />} />

        <Route path="/" element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }>


          <Route index element={<Dashboard />} />
          <Route path="tax" element={<Tax />} />
          <Route path="myaccount" element={<MyAccount />} />
          <Route path="signout" element={<SignoutPage />} />
          {/* <Route path="extractlist" element={<Extract />} /> */}
          <Route path="extract" element={<Receipt />} />
          <Route path="deposit">
            <Route index element={<Deposit />} />
            <Route path="tedordoc" element={<DepositInfoTedDoc />} />
            <Route path="invoices-list" element={<DepositInvoicesList />} />
            <Route path="pixmykeys" element={<DepositPixMyKeys />} />


            <Route path="invoice">
              <Route index element={<DepositNewInvoice />} />
              <Route path="confirm" element={<DepositNewInvoiceConfirm />} />
            </Route>
          </Route>
          <Route path="charge" element={<Charge />} />
          <Route path="transfer">
            <Route index element={<Transfer />} />
            <Route path="list" element={<TransferList />} />
            <Route path="subscribe" element={<TransferSubscribe />} />
            <Route path="confirm" element={<TransferConfirm />} />
          </Route>
          <Route path="/pix">
            <Route index element={<PixOptions />} />
            <Route path="list" element={<PixList />} />
            <Route path="mykeys" element={<PixMyKeys />} />
            <Route path="mykeys/new" element={<PixNewKey />} />
            <Route path="transfer">
              <Route index element={<PixTransfer />} />
              <Route path="confirm" element={<PixConfirm />} />
            </Route>
            <Route path="copiaecola">
              <Route index element={<PixTransferCopiaECola />} />
              <Route path="confirm" element={<PixConfirmCopieEcola />} />
            </Route>
          </Route>
          <Route path="/paybills">
            <Route index element={<PayBills />} />
            <Route path="invoice" element={<PayBillsPayInvoice />} />
            <Route path="info-invoice" element={<PayBillsInfoInvoice />} />
            <Route path="subscribe" element={<PayBillsSubscribe />} />
            <Route
              path="confirm-invoice"
              element={<PayBillsInvoiceConfirm />}
            />
            <Route
              path="schedule-payments"
              element={<PayBillsSchedulePayments />}
            />
          </Route>

          <Route path="gerenciar" element={<GerenciarContas />} />
          <Route path="aprovacoes/:id" element={<Aprovacoes />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default routes;
